// import moment from "moment";
import * as dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
dayjs.extend(relativeTime);
import "dayjs/locale/en"; // 英语 默认
import "dayjs/locale/zh"; // 简体
import "dayjs/locale/zh-tw"; // 台湾繁体
import "dayjs/locale/ja"; //日语
import "dayjs/locale/fr"; // 法语
import "dayjs/locale/de"; // 德语
import "dayjs/locale/es"; // 西班牙语

// dayjs.locale('ja') // use locale globally

// dayjs().locale('de').format()

export default {
  //
  formatTime(dateStr, format) {
    if (!dateStr) return null;
    if (format) return dayjs(dateStr).format(format);
    return dayjs(dateStr).format("YYYY/MM/DD HH:mm:ss");
  },

  // 相对时间
  DateFormNowNt(dateStr, locale) {
    let d = dayjs(dateStr);
    if (locale) {
      d = dayjs.locale(locale);
    }
    return dayjs(dateStr).fromNow(true);
  },
  DateToNowNt(dateStr) {
    return dayjs(dateStr).toNow(true);
  },
  TimeFromNow(dateStr) {
    const targetTime = dayjs(dateStr); // 设定目标时间
    const currentTime = dayjs(); // 获取当前时间

    if (targetTime.isBefore(currentTime)) {
      return "00:00:00"; //不是未来时间直接返回
    }

    const diffDuration = dayjs.duration(targetTime.diff(currentTime)); // 计算时间差
    const hoursDiff = diffDuration.hours();
    const minutesDiff = diffDuration.minutes();
    const secondsDiff = diffDuration.seconds();

    const formattedTime = `${hoursDiff
      .toString()
      .padStart(2, "0")}:${minutesDiff
      .toString()
      .padStart(2, "0")}:${secondsDiff.toString().padStart(2, "0")}`;
    return formattedTime;
  },
  isBefore(dateStr) {
    return dayjs().isBefore(dayjs(dateStr));
  },
  // 获取操作系统
  getSystem() {
    const str = navigator.userAgent.toLowerCase();

    let start = str.indexOf("; ");
    let end = str.indexOf(")");
    let version = str.substring(start + 2, end);
    let system;
    if (version.includes("win")) system = "win";
    if (version.includes("linux")) system = "linux";
    //  let v = "";
    if (version.includes("mac")) {
      system = "darwin";
    } else {
      //v = ""; version.includes("64") ? (v = "-amd64") : (v = "-386");
    }

    return system; //+ v;
  },
  // 乘法
  accMul(arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();
    try {
      m += s1.split(".")[1].length;
    } catch (e) {}
    try {
      m += s2.split(".")[1].length;
    } catch (e) {}
    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
    );
  },
};

import axios from "axios";
import jwt from "jsonwebtoken";

export default function (url, data, method, headers, responseType) {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      data: data,
      method: method,
      headers: headers,
      responseType: responseType,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });
}

// 对象格式查询字符串方法
export {
  getSearchString,
  hashCode,
  pickColor,
  saveTokenInfo,
  isScrollShow,
  unique,
  getRelativePosition,
  getLineOptions,
  getZoomCenterPoint,
  getDistance,
  isMobile,
  GetDownLoadCommand,
  mapSpeedToLine,
  convertToBytes,
};

/**
 * 获取两点间距离
 * @param {object} a 第一个点坐标
 * @param {object} b 第二个点坐标
 * @returns
 */
function getDistance(a, b) {
  const x = a.x - b.x;
  const y = a.y - b.y;
  return Math.hypot(x, y); // Math.sqrt(x * x + y * y);
}

/**
 *
 * @param {object} event 鼠标touch事件对象
 * @returns {object} 返回双指缩放中心
 */
function getZoomCenterPoint(event) {
  var touch1 = event.touches[0];
  var touch2 = event.touches[1];
  var zoomCenterX = (touch1.pageX + touch2.pageX) / 2;
  var zoomCenterY = (touch1.pageY + touch2.pageY) / 2;
  return { x: zoomCenterX, y: zoomCenterY };
}

/**
 *  根据类型返回曲线弧度设置
 *
 * @param {number} type 根据连线位置计算的共四种类型对应四种曲线弧度
 * @param {number} radian 弧度的基础数值 设为常量
 * @param {number} size 缩放的倍率
 * @return {Object} 返回line的设置options
 */
function getLineOptions(type, radian, size) {
  let options;
  switch (type) {
    case 1:
      options = {
        startSocketGravity: [radian * size, 0],
        endSocketGravity: [-radian * size, 0],
        // startSocket: "right",
        // endSocket: "left",
      };
      break;
    case 2:
      options = {
        startSocketGravity: [-radian * size, 0],
        endSocketGravity: [-radian * size, 0],
        startSocket: "right",
        endSocket: "right",
      };
      break;
    case 3:
      options = {
        startSocketGravity: [-radian * size, 0],
        endSocketGravity: [radian * size, 0],
        startSocket: "left",
        endSocket: "auto",
      };
      break;
    case 4: //同左边或者同右边
      options = {
        startSocketGravity: [radian * size, 0],
        endSocketGravity: [radian * size, 0],
        startSocket: "left",
        endSocket: "right",
      };
      break;
  }
  return options;
}

function getSearchString(key) {
  // 获取URL中?之后的字符
  if (process.browser) {
    let str = window.location.search;
    str = str.substring(1, str.length);

    // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
    let arr = str.split("&");
    let obj = new Object();

    // 将每一个数组元素以=分隔并赋给obj对象
    for (let i = 0; i < arr.length; i++) {
      let tmp_arr = arr[i].split("=");
      obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    return obj;
  }
}

function hashCode(str) {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function pickColor(str) {
  return `hsl(${hashCode(str) % 360}, 100%, 80%, 0.7)`; //逗号分隔 第四个参数为不透明度 小数0到1
}

// 封装的token 保存本地方法
function saveTokenInfo({ access_token, refresh_token }) {
  if (!access_token) return;
  let str = jwt.decode(access_token);
  if (str) {
    let obj = str;
    obj.token = access_token;
    obj.refresh_token = refresh_token;
    localStorage.setItem("_tokenInfo", JSON.stringify(obj));
  }
}

/**
 *  计算元素是否因为滚动导致元素不可见方法
 *
 * @param {string} id 元素的html id
 * @return {Object} 返回
 */
function isScrollShow(id) {
  //判断元素是否因为滚动界面不可见了，认为只剩1/2的高度就不可见了
  const elem = document.getElementById(id);
  if (!elem) return { show: false };
  const bounds = elem.getBoundingClientRect();
  const elemHeight = bounds.bottom - bounds.top; // 这里是0来的
  let show = true;
  let up = null;
  let down = null;

  // 这里之所以找 disk-f 是 为了判断path是否还在显示范围内
  const pBounds =
    elem.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect(); //这里需要根据结构重新写

  if (pBounds.top > bounds.top) {
    show = false;
    up = true;
  }
  if (pBounds.bottom < bounds.bottom) {
    down = true;
    show = false;
  } // 除了判断是否显示还啊要判断在上还是在下
  return { up, show, down };
}

// 数组去重
function unique(arr) {
  let set = new Set();
  if (!Array.isArray(arr)) {
    console.log("type error!");
    return;
  }
  arr = arr.sort();
  var arrry = [arr[0]];
  for (var i = 1; i < arr.length; i++) {
    if (arr[i] !== arr[i - 1]) {
      arrry.push(arr[i]);
    }
  }
  return arrry;
}

const clientWidth = document.body.clientWidth;

// 使用相对窗口位置的x来比较 谁在左谁在右
function getRelativePosition(startId, endId) {
  if (!document.getElementById(startId) || !document.getElementById(endId)) {
    return { start: "left", end: "right" };
  }
  let startRect = document.getElementById(startId)?.getBoundingClientRect();
  let endRect = document.getElementById(endId)?.getBoundingClientRect();
  let diff = startRect.x - endRect.x;
  let obj = {};
  if (diff < 0) {
    obj.start = "right";
    if (Math.abs(diff) > startRect.width) {
      obj.end = "left";
    } else {
      obj.end = "right";
    }
  }
  if (diff > 0) {
    obj.start = "left";
    if (Math.abs(diff) > startRect.width) {
      obj.end = "right";
    } else {
      obj.end = "left";
    }
  }

  // 改一下设定 默认都放在右边 然后判断如果右边离开视口了再显示左边
  if (diff == 0) {
    if (startRect.x + startRect.width > clientWidth - 100) {
      obj.start = "left";
      obj.end = "left";
    } else {
      obj.start = "right";
      obj.end = "right";
    }
  }

  return obj;
}

// 判断浏览器是手机还是pc
function isMobile() {
  // 获取用户代理字符串
  var userAgent = navigator.userAgent;

  // 判断是否为移动设备
  var isMobile =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );

  if (isMobile) {
    return true;
  } else {
    return false;
  }
}

// 根据不同域名返回不同的下载命令
function GetDownLoadCommand() {
  const host = location.host;
  let env = "";
  switch (host) {
    case "filepipe.dev":
      env = "-s -- -d dev";
      break;
    case "dev.filepipe.cn":
      env = "-s -- -d dev_cn";
      break;
    case "filepipe.cn":
      env = "-s -- -d cn";
      break;
    case "filepipe.app":
      env = "";
      break;
  }
  return `curl --proto '=https' --tlsv1.2 -sSf ${location.origin}/api/v1/sh/install.sh | sh ${env}`;
}

/**
 *  定义一组标准，根据传入的速度返回不同的 动画持续时间以显示速度
 *
 * @param {number} speed  传输速度
 * @return {number} 返回 一个数字也就是 动画持续时间 越小越快
 */
function mapSpeedToLine(speed) {
  // 将速度分为10个档位 单位的b  最小1k 最大1g
  const gear = [
    1000000000, 889888, 778777, 667666, 556555, 445444, 334333, 223222, 112111,
    1000,
  ];

  const byte = convertToBytes(speed);
  let index = gear.findIndex((e) => byte >= e);
  const speedList = [100, 300, 500, 800, 1000, 1500, 1800, 2000, 2500, 3000];
  return speedList[index];
}

/**
 *  转换 10b/s 这类字符串为最小单位
 *
 * @param {string} data  传输速度
 * @return {number} 返回 一个数字 最小单位
 */
function convertToBytes(data) {
  if(!data) return 0
  data = data.trim();
  let parts = data.split(" ");
  if (parts.length !== 2) {
    throw new Error("Invalid data format");
  }
  let value = parseFloat(parts[0]);
  let unit = parts[1];

  if (unit === "KB/s") {
    return value * 1000;
  } else if (unit === "MB/s") {
    return value * 1000000;
  } else if (unit === "GB/s") {
    return value * 1000000000;
  } else if (unit === "B/s") {
    return value;
  } else {
    throw new Error("Invalid unit provided");
  }
}

// const list = [
//   "1 KB/s",
//   "112.111 KB/s",
//   "223.222 KB/s",
//   "334.333 KB/s",
//   "445.444 KB/s",
//   "556.555 KB/s",
//   "667.666 KB/s",
//   "778.777 KB/s",
//   "889.888 KB/s",
//   "1000 MB/s",
// ]

const _console = console;
const createlog = (util) => (...args) => {
  const fun = _console[util] ? _console[util] : _console.log;
  fun.apply(void 0, args);
};

const hello = (title, version, commitHash) =>
  createlog('log')(
    `%c ${title} %c ${version} ${commitHash}`,
    'padding: 2px 1px; border-radius: 3px 0 0 3px; color: #fff; background: #606060; font-weight: bold;',
    'padding: 2px 1px; border-radius: 0 3px 3px 0; color: #fff; background: #42c02e; font-weight: bold;'
  );

hello('filepipe-front', '0.9.246', '59cac8e32bcd953cb4acffdda821d81ff4301ea9');

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./version";

import "bootstrap/dist/js/bootstrap.min.js";
import Notifications from "@kyvg/vue3-notification";
import "bootstrap-icons/font/bootstrap-icons.css";

import VueTippy from "vue-tippy";
import { Tippy } from "vue-tippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { notify } from "@kyvg/vue3-notification";
import Loading from "@/components/layer/Loading.vue";
import Empty from "@/components/layer/Empty.vue";

import $axios from "@/utils/axios";
import $filters from "@/utils/filters";
import Clipboard from "v-clipboard";

import vue3PhotoPreview from "vue3-photo-preview";
import "vue3-photo-preview/dist/index.css";

import "highlight.js/styles/vs2015.css";

import i18n from "@/i18n";

const app = createApp(App);
app.use(i18n);

app.provide("$", { $axios, $notify: notify, $filters });

app.config.devtools = true;

app.component("Tippy", Tippy);

app.component("Loading", Loading);
app.component("Empty", Empty);

app.config.globalProperties.$filters = $filters;

app.use(
  VueTippy,
  // optional
  {
    directive: "tippy", // => v-tippy
    component: "tippy", // => <tippy/>
  }
);

app.use(vue3PhotoPreview);
// app.use(hljsVuePlugin);
app.use(Notifications);
app
  .use(store)
  .use(router)
  .use(Clipboard)
  .mount("#app");

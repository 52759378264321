import en from "./en";
import zhHans from "./zhHans";
import zhHant from "./zhHant";
import ja from "./ja";
import fr from "./fr";
import de from "./de";
import es from "./es";

const CN = getConfig().cn;

const messages = {
  en,
  zhHans,
  zhHant,
  ja,
  fr,
  de,
  es,
};

const langMap = {
  en: "en",
  "zh-CN": "zhHans",
  "zh-TW": "zhHant",
  ja: "ja",
  fr: "fr",
  de: "de",
  es: "es",
};

import { createI18n } from "vue-i18n";
const i18n = createI18n({
  legacy: false,
  locale: getLocale(), // set locale
  fallbackLocale: CN ? "zhHans" : "en", // set fallback locale
  messages, // set locale messages
});

export default i18n;

// 英语：English / en 默认英语
// 中文（简体）：Chinese (Simplified) / zh-Hans
// 中文（繁体）：Chinese (Traditional) / zh-Hant 台湾繁体
// 日语：Japanese / ja
// 法语：French / fr
// 德语：German / de
// 西班牙语：Spanish / es

function getNavLanguage() {
  let lang = navigator.language;
  console.log(langMap[lang]);
  return langMap[lang];
}

function getLocale() {
  if (CN) return "zhHans";
  return localStorage.getItem("_language") || getNavLanguage() || "en";
}

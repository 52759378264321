export default {
  header: {
    myPlans: "私の計画",
    Upgrade: "アップグレード",
  },
  downloadModal: {
    title: "Nodeクライアントについて",
    download: "をダウンロードする",
    history: "歴史バージョン",
    title2: "Linux、macOS:",
    bit: "-BIT",
    p1: "もしLinux、macOS、または他のUnixライクなオペレーティングシステムを使用しており、FilePipe Nodeをダウンロードしたい場合は、ターミナルで以下のコマンドを実行し、画面の指示に従ってください。",
    p2: "Windowsを使用している場合、FilePipe Nodeを使用するには、対応するクライアントをダウンロードしてください。オペレーティングシステムに基づいて選択してください。",
  },
  subModal: {
    title: "購読情報",
    tableTitle: "あなたのサブスクリプション",
    th1: "プラン",
    th2: "期間",
    th3: "支払い方法",
    time1: "あなたは参加しました。",
    time2: "次回の更新日",
    time3: "キャンセルの時期",
    reSubscribe: "再登録",
    confirm: "キャンセルを確認するにはもう一度クリックしてください",
    changeTip: "ご契約中のパッケージに新しい変更があります",
    oldPrice: "旧価格",
    newPrice: "新価格",
    effectiveDate: "開始日",
    updateUrlText: "PayPalで確認するにはこちらをクリックしてください！",
  },
  home: {
    title1: "非常強力な",
    title2: "ファイル転送システム",
    slogan:
      "複数デバイスのファイル転送を非常に簡単にすることを可能にする、前例のないほど強力なファイル転送システムです。",
    button1: "今すぐお試しください。",
    backTop: "ページのトップに戻る",
    download: {
      title: "シンプルなインストールと起動",
      1: "グループを作成し、APIキーを取得する",
      2: "あなたのノードでクライアントをダウンロードして実行してください",
      3: "「ダッシュボード」で転送を管理してください。",
    },
    features: {
      title1: "あなたのファイルを必要に応じて自由に流れるようにしてください。",
      subtitle11: "一度設定すれば、あとは必要な時にファイルを流せばよい,",
      subtitle12:
        "そして、指定したディレクトリ内のファイルは自動的に指定した場所に移動します。",
      q1: "複数のデバイス間でファイル共有に問題がありますか？",
      q2: "複数のディスク間でのファイル転送に問題がありますか？",
      q3: "我々のプログラムは、設定に従って自動的にファイルを指定したパスに転送します。",
      q4: "異なるフォルダ、異なるハードディスク、さらには異なるオペレーティングシステムであっても、当社のプログラムは設定に従って自動的にファイルを指定されたパスに転送します。",
      title2: "ワーキングモードは完全自動です。",
      subtitle21: "一度初期設定を行えば、その後は自動で動作します。",
      subtitle22: "一度初期設定するだけで、あとは自動で処理が行われます。",
      subhead1: "自動的にグループに参加しましょう！",
      info1:
        "グループキーを使用してノードを起動し、手動で追加することなく自動的にグループに参加できます。",
      subhead2: "ハードディスククラスターを管理しましょう！",
      info2:
        "ノードプログラムにハードディスクを接続することで、大量のハードディスククラスタを簡単に管理できます。これは、高速、便利、かつわかりやすい方法です",
      subhead3: "自動的にファイルのフローを実現します！",
      info3:
        "指定した接続設定を一度行うだけで、指定されたディレクトリにファイルが現れると、自動的にターゲットディレクトリに流れ込み、LANの別のデバイスにも送信されます。",
      title3: "Webコンソールの概要",
      subtitle3:
        "一目でノードを確認できるウィンドウベースのノード管理機能があります。",
      console: {
        title1: "自由にグループを作成してください。",
        info1:
          "全てのノードは、お互いに干渉することなく、グループ化されているため、簡単に管理できます。",
        guide: "あなた自身のグループを作成してください。",
        title2: "豊富なコンテンツと明確な構造",
        info2:
          "各ノードは、追加されたディスクとパスを表示し、一目で見やすい構造になっています。",
        title3: "明確なステータス表示",
        info3:
          "各パスの作業状態を明確に表示します。例えば、現在転送中のファイルの数、転送速度、転送されたファイルの数、および転送の完了時間などが含まれます。",
        title4: "両端を同期します。",
        info4:
          "ウェブコンソールとノードはリアルタイムで同期され、ウェブ上で新しいパスや接続をいつでも作成できます。",
        title5: "リアルタイムファイルアップロードリスト",
        info5:
          "コンソールページのサイドバーには、アップロード中のファイルに関するリアルタイム情報が表示されます。ファイルサイズ、アップロード速度、およびさまざまな詳細情報が含まれます。",
        title6: "Windowsのようにノードを管理する",
        info6: "自由に拡大縮小して、自分の好みに合わせて配置できます。.",
        title7: "ノードについて",
        info7:
          "ノードは対応するマシンのIPアドレスを表示し、簡単に管理できるようにカスタマイズできます。",
        title8: "ディスクについて",
        info8:
          "パスを追加すると、ハードディスクの場所が自動的に検出され、リアルタイムの容量表示や高温警告が表示されます。",
        title9: "パスについて",
        info9:
          "パスとは、ファイルが保存されているフォルダーパスであり、既にあなたのマシンに存在している場合も、存在しない場合も自由にパスを作成できます。 ",
      },
      title4: "簡単な設定と起動",
      subtitle4: "クライアントを起動するために必要なのは、一度の設定のみ。",
      example: {
        title1: "設定ファイルは簡潔明瞭",
        title2: "設定完了後すぐに起動できる",
        info1:
          "他に必要な操作や要件がなく、直接起動することができ、バックグラウンドで動作しても他のプログラムの正常な動作には影響を与えません。",
        info2:
          "設定が完了したら、ノードが配置されたディレクトリのターミナルに入力します。",
        info3: "Windows の場合は、ダブルクリックでも開くことができます。",
        linux: "Linux システムでは、次のコマンドを入力します：",
        macOS: "macOS システムでは、次のコマンドを入力します：",
        guide: "まだクライアントプログラムをお持ちでない場合は、。",
        download: "立即ダウンロード",
      },
      title5: "さまざまな利点",
      subtitle5:
        "FilePipeは、マルチデバイスファイル転送を前例のないほど簡単にすることができます。",
      kinds: {
        title1: "さまざまなシステムに対応",
        info1:
          "Windows、Mac、Linuxを含む、さまざまなシステムに対応するクライアントプログラムがあり、Webターミナルではファイル転送をリアルタイムで制御および表示することができます。",
        title2: "同梱のWebコンソール",
        info2:
          "Webコンソールでは、すべてのノードおよび各ノードの直接ファイル転送パイプラインの作成および管理が便利に行えます。",
        title3: "登録不要",
        info3:
          "Google、GitHubなどの第三者を使用して直接ログインし、別途アカウントを登録する必要はありません。便利で速いです。",
        title4: "セキュリティはデフォルトで確保されます",
        info4:
          "ファイルの転送は常にLAN内で行われ、ネットワークに接続されることはありません。ファイルの漏洩に心配する必要はありません。",
      },
      ready: "FilePipeを使用する準備はできましたか？",
      or: "または",
      downloadText:
        "FilePipeのクライアントプログラムをダウンロードして、ファイル管理を開始してください。",
      downloadC: "クライアントをダウンロード",
      downloadAll: "すべての過去バージョン",
      subscribeT: "購読でより多くのサービスを利用",
      subscribeinfo:
        "StripeやPayPalなどの複数の支払い方法をサポートし、定期購読サービスを提供しています。",
      unsubscribe: "いつでも購読を解除",
    },
    footer: {
      company: "会社",
      policy: "プライバシーポリシー",
      terms: "利用規約",
      termsTitle: "FilePipe利用規約",
    },
  },
  pricing: {
    monthly: "毎月",
    yearly: "毎年",
    current: "現在",
    pre: "前",
    month: "月",
    year: "年",
    reHome: "ホームに戻る",
    confirming: "確認中",
    features: "特徴",
    expired: "期限切れ",
    invitationCode: "招待コード",
    invitePlacehold: "招待コードを入力してください",
    inviteExplain:
      "他人から得た招待コードを入力できます。招待コードを使用すると、他の人に利益をもたらすことができます。持っていない場合は空白のままにしておいてください",

    udModal: {
      title: "サブスクリプション",
      head: "サブスクリプションパッケージを切り替える：",
      text: "切り替え後、支払いが必要です",
      tip: "続行しますか",
      udS: "サブスクリプションの切り替えに成功しました",
      willToHome: "ホームページに戻ります",
    },
    payModal: {
      title: "支払い",
      head: "パッケージを購入する",
      needToPay: "支払う必要があります",
      text: "Stripeは、支払いを保留したままにしていることを検出し、自動的に契約を更新しました。",
      paying: "支払い中",
      pay: "支払う",
      paySuccess:
        "成功しました！支払いを受け付けました。ホームページに戻ります。",
      next: "次のステップ",
      saveMethodSuccess:
        "支払い方法が正常に保存されました。注文を送信しています。",
    },
  },
  notFount: {
    title: "ページが見つかりません」",
    info: "このページは存在しないようです",
    goback: "戻る",
  },
  login: "ログイン",
  logout: "ログアウト",
  logining: "ログイン中",
  loginTitle: "ログイン - ",
  docs: "ドキュメント",
  cancel: "キャンセル",
  save: "保存",
  saved: "保存済み",
  sure: "はい",
  submit: "送信",
  confirm: "確認",
  subscribe: "購読する",
  ok: "わかった",
  working: "作業中",
  offline: "オフライン",
  error: "エラー",
  waiting: "待機中",
  etc: "その他",
  undone: "このアクションは元に戻せません。",
  copied: "コピーしました",
  copy: "コードをクリップボードにコピー",
  empty: "空の (からの)",
  console: {
    text: "コンソール",
    group: "グループ",
    node: "ノード",
    path: "パス",
    name: "名前",
    download: "クライアントのダウンロード",
    createToStart: "グループを作成して開始",
    newGroup: "新しいグループ",
    addNode: "ノードを追加",
    nodes: "ノード",
    paths: "パス",
    pipes: "パイプ",
    createNewG: "新しいグループを作成",
    groupName: "グループ名",
    createG: "グループを作成",
    groupSet: "グループ設定",
    general: "一般",
    apikey: "API Key",
    member: "メンバー",
    extra: "追加設定",
    groupId: "グループID",
    rfapikey: "API Key を更新",
    deleteGroupQ: "このグループを削除してもよろしいですか",
    dgroup: "グループを削除",
    confirmDeleteG: "削除を確認するにはもう一度クリックしてください",
  },
  nodes: {
    IP: "IPアドレス",
    name: "名前",
    mac: "MACアドレス",
    mountP: "マウントポイント",
    backC: "中心に戻る",
    resetL: "レイアウトをリセット",
    fromIp: "送信元IP",
    from: "送信元",
    to: "宛先",
    nameModal: {
      title: "ノード名を設定",
      label: "新しい名前",
    },
    deletePModal: {
      title: "パスを削除",
      tip: "このパスを削除してもよろしいですか？",
      fromMou: "マウントポイント",
      fromIp: "送信元IP",
      delSource: "ソースファイルを削除する（注意して選んでください！）",
    },
    cPathModal: {
      title: "新しいパスを作成",
      label: "新しいパス",
      cPath: "パスを作成",
    },
    delNodeModal: {
      title: "ノードを削除",
      tip: "このノードを削除してもよろしいですか？",
      text: "削除操作は、このノードに関連するデータをクリアしますが、クライアントプログラムをノード上で再実行すると、ノードはここに再び表示されます。",
    },
    delDiskModal: {
      title: "このディスクのすべてのマウントパスを削除します",
      tip: "本当に削除してもよろしいですか",
      strong: "このディスクのすべてのマウントパス",
    },
    resetLModal: {
      title: "レイアウトをリセット",
      tip: "レイアウトをリセットしてよろしいですか？",
    },
    mfModal: {
      title: "転送パイプを作成",
      title1: "パス間でファイル転送を確立する",
      title2: "同期モード",
      label1: "相対同期",
      formText1: "送信元から宛先へファイルを転送します",
      label2: "絶対同期",
      formText2:
        "送信元と宛先のファイルを絶対的に同じ状態に保ちます。宛先の余分なファイルは削除されます",
      title3: "強制上書き",
      label3: "既に存在するファイルを強制的に上書きしますか？",
      title4: "md5の検証",
      label4: "md5を検証しますか？",
      title5: "フィルター",
      title6: "パイプを削除",
      formText5:
        "ワイルドカードパターンを使用して、転送するファイルを除外または含めることができます。すべてを転送する場合は「除外」にフィルターを設定しないでください。",
      label5: "除外",
      label6: "含める",
      label7: "ワイルドカードフィルターを入力してください",
      alertDanger:
        "利用可能なフィルターを含めるように設定する必要があります。そうしない場合は何も転送されません。",
      placeHolder: "例えば*.txt",
    },
    pipeInfoModal: {
      title: "転送パイプ",
      delPipe: "パイプを削除",
      details: "詳細",
      speed: "スピード",
      log: "ログ",
      autoScroll: "自動スクロール",
    },
    delPipeModal: {
      title: "パイプを削除",
      tip: "このパイプを削除してもよろしいですか？",
    },
  },
  node: {
    state: "状態",
    version: "バージョン",
    newPath: "新しいパス",
    setNodeName: "ノード名を設定",
    deleteNode: "ノードを削除",
    tempatureTip: "ハードディスク温度が{d}℃に達しています",
    deleteAll: "このディスク上のすべてのマウントパスを削除してください",
    delPath: "マウントパスを削除",
    total: "合計",
    free: "空き容量",
    readOnly: "読み取り専用",
    readOnlyTip: "このパスは読み取り専用で、書き込みはできません",
  },
  wildcardTable: {
    td1: "文字",
    td2: "説明",
    td3: "例",
    p1: "文字 c に一致します（c != '', '-', ']'）",
    p2: "区切り文字でない文字のシーケンスに一致します。",
    finds: "見つけます",
    and: "そして",
    butnot: "しかし",
    or: "または",
    p3: "区切り文字でない文字を一つだけに一致します。",
    p4: "ブラケット内の文字に一致します。",
    p5: "文字 c に一致します。",
    p6: "lo <= c <= hi の場合、文字 c に一致します。",
  },
  notify: {
    unableDown: "一時的にダウンロードできません",
    netError: "ネットワークエラーが発生しました。後でもう一度お試しください。",
    reqSuccess: "リクエストが成功しました",
    upSuccess: "更新に成功しました",
    pleaseWait: "リクエストを送信しました。結果をお待ちください",
    invalid: "無効な操作です",
    paypalNoPro:
      "PayPalサービスは提供できません。他の支払い方法を選択してください",
    createdSubSuccess: "購読を正常に作成しました",
    unableLoadPaypal: "PayPalを読み込めません。後でもう一度お試しください",
    groupnameExists: "グループ名がすでに存在しています",
    loginAgain: "再度ログインしてください",
    loginFirst: "最初にログインしてください",
    noline:
      "ターゲットパスに接続できません。そのステータスを確認してください。",
  },
  tickets: "チケット",
  ticket: {
    ticket: "Tickets",
    empty: "Empty",
    subTitle:
      "Create a ticket to provide feedback on issues and seek assistance",
    open: "Open",
    close: "Close",
    all: "All",
    cancel: "Cancel",
    submit: "Submit",
    sure: "Sure",
    created_at: "Created at",
    updated_at: "Updated at",
    undone: "This action can't be undone.",
    title: "Title",
    createModal: {
      createBtn: "Create Ticket",
      placeHolder1: "Please enter title",
      placeHolder2: "Please describe the problem you have encountered",
      formText:
        "For easier communication with the staff, we recommend using English as much as possible.",
      title: "Title",
      category: "Category",
      content: "Content",
      attachment: "Attachment",
      upload: "Upload",
    },
    putModal: {
      title: "Edit Ticket",
    },
    delModal: {
      title: "Delete Ticket",
      tip: "Are you sure to delete this ticket?",
    },
    closeModal: {
      title: "Close Ticket",
      tip: "Are you sure to close this ticket?",
    },
    commented: "Commented",
    noComment: "No comment",
    yourComment: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: "Delete comment",
  },
  createdAt: "Created at",
  updatedAt: "Update at",
  close: "Close",
  open: "Open",
  all: "All",
  title: "Title",
  category: "Category",
  content: "Content",
  comment: {
    commented: "commented",
    title: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: {
      title: "Delete comment",
      tip: "Are you sure delete this comment",
    },
  },
  balance: {
    balance: "残高",
    order_id: "注文ID",
    type: "タイプ",
    income: "収入",
    outgo: "支出",
    created_at: "作成日時",
  },
  userInfo: {
    title: "ユーザー情報",
    user: "ユーザー",
    username: "ユーザー名",
    copied: "クリップボードにコピー済み",
    invitation_code: "招待コード",
    used_inv_code: "使用済み招待コード",
    code_describe:
      "他のユーザーがサブスクリプションを購入する際にあなたの招待コードを使用すると、サブスクリプションを購入する際に割引が適用されます",
  },
  fileTree: {
    emptyFolder: "空のフォルダ",
    newFile: "新しいファイル",
    newFilePlaceholder: "ファイル名を入力してください",
    newFloder: "新しいフォルダ",
    newFloderPlaceholder: "フォルダ名を入力してください",
    delete: "削除",
    deletePrompt: "このファイルまたはパスを削除してもよろしいですか？",
    errorName: "無効なファイル名",
    errorfloder: "無効なフォルダ名",
    copy: "コピー",
    paste: "貼り付け",
    sameName: "同じファイル名",
    sameFolderName: "同じフォルダ名",
  },
  oneTimeSync: {
    modalTitle: "ワンタイム同期",
    title1: "ワンタイムのクイック転送を確立する",
  },
  subscriptions: {
    title: "過去のサブスクリプション",
    planname: "プラン名",
    amount: "金額",
    status: "ステータス",
    expired: "有効期限",
    notExpired: "有効",
    createtime: "開始日",
    stopTime: "終了日",
    PayMethod: "支払い方法",
  },
  paymentMethod: {
    title: "支払い方法",
    title1: "Stripeのデフォルト支払い方法",
    createMethodTip:
      "現在デフォルトの支払い方法はありません。クリックして作成してください。",
    cardNumber: "カード番号",
    created: "作成日時",
    expire: "有効期限",
    deleteQ: "この支払い方法を削除してもよろしいですか？",
    deleteTip:
      "デフォルトの支払い方法を削除すると、将来のサブスクリプションの支払いが失敗する可能性があるため、注意して操作してください。",
    confirmDelete:
      "支払い方法の削除を確認するにはもう一度クリックしてください。",
  },
  groupMember: {
    title: "グループメンバー",
    invite: "招待",
    remove: "削除",
    removePrompt: "このメンバーを削除してもよろしいですか",
    ownerPrompt: "グループのオーナーシップを{name}に移行してもよろしいですか",
    changeOwner:
      "このメンバー{name}にグループの所有権を移譲してもよろしいですか？",
    setOwner: "所有者を設定",
    name: "名前",
    owner: "所有者",
    invited: "招待済み",
    joined: "参加時間",
  },
  InviteMember: {
    title: "メンバーを招待する",
    searchlable: "ユーザーを検索",
    searchTip: "ユーザー名、電子メール、またはユーザーIDのサポート",
    invited: "招待済み",
    member: "メンバー",
  },
  Invitation: {
    title:
      "{name} {email}が作成したグループ（{groupName}）への参加を招待されました。招待を受け入れるにはボタンをクリックしてください。",
    accept: "招待を受け入れる",
    describe: "グループへの参加により、以下にアクセスできます",
    agreed: "同意",
    expired: "期限切れ",
  },
  yes: "はい",
  no: "いいえ",
  notification: {
    title: "に知らせる",
  },
};

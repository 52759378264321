<template>
  <div>
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#oneTimeSyncModal"
      id="openOneTimeSync" hidden>
    </button>

    <!-- Modal -->
    <div class="modal fade" id="oneTimeSyncModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ t('oneTimeSync.modalTitle') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="oneTimeSyncInfo">
            <h4>{{ t('oneTimeSync.title1') }}</h4>
            <div class="text-break">
              <strong>{{ t('nodes.from') }}:</strong>
              {{ oneTimeSyncInfo.from_ip }}:{{ oneTimeSyncInfo.sources &&
                oneTimeSyncInfo.sources[0] }}
            </div>
            <div class=" text-break">
              <strong> {{ t('nodes.to') }}:</strong>
              {{ oneTimeSyncInfo.to_ip }}:{{ oneTimeSyncInfo.destination }}
            </div>
            <div class="my-3">
              <h4>{{ t('nodes.mfModal.title3') }}</h4>
              <div class="form-check ">
                <input class="form-check-input border" type="checkbox" id="oneTimeForceOverwrite"
                  v-model="oneTimeSyncInfo.force_over_write" />
                <label class="form-check-label" for="oneTimeForceOverwrite">
                  {{ t('nodes.mfModal.label3') }}
                </label>
              </div>
            </div>
            <div class="my-3">
              <h4>{{ t('nodes.mfModal.title4') }}</h4>
              <div class="form-check ">
                <input class="form-check-input border" type="checkbox" id="oneTimemd5"
                  v-model="oneTimeSyncInfo.validate_md5" />
                <label class="form-check-label" for="oneTimemd5">
                  {{ t('nodes.mfModal.label4') }}
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref="closeModal"
              @click="handleCancle"> {{ $t('cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="subimt">{{ t('submit') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, inject, watch, } from 'vue'
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: '',
  props: [],
  setup() {
    const { $axios, $notify } = inject('$')
    const v = reactive({
      filters: [],
      newFilter: null,
      oneTimeSyncInfo: {},
      closeModal: null,
    })
    const store = useStore()
    const { t } = useI18n()


    watch(() => store.state.oneTimeSyncInfo, (val) => {
      if (val) {
        let obj = Object.assign(val, {
          force_over_write: false, validate_md5: false
        });
        v.oneTimeSyncInfo = obj
      }

    })

    async function subimt() {
      try {
        let obj = v.oneTimeSyncInfo
        const res = await $axios.post('/api/v1/oneTimeSync', obj)
        $notify({ type: "info", text: t('notify.pleaseWait') });
        v.closeModal.click();
      } catch (error) {
        console.error(error);
      }
    }

    function handleCancle(params) {
      store.commit("SET_ONETIMESYNC", null)
      store.commit("SET_TARGETPOSITION", {})
    }

    return {
      ...toRefs(v), t, handleCancle, subimt
    }
  }
}
</script>
<style scoped></style>

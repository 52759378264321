<template>
  <div class="modal fade" id="PaymentMethodModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t(pre + 'title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeSettings"
            @click="handleClose"></button>
        </div>
        <div class="modal-body" style="min-height:500px">
          <div class="d-flex align-items-start">
            <div class="nav flex-column nav-pills " style="width:120px" id="v-pills-tab" role="tablist"
              aria-orientation="vertical">
              <a class="nav-link active" id="payment-method-info-tab" data-bs-toggle="pill" href="#payment-method-info"
                role="tab" ref="paymentMethodInfo">
                {{ $t('console.general') }}</a>

              <a class="nav-link" id="v-pills-delete-tab" data-bs-toggle="pill" href="#payment-method-delete" role="tab"
                aria-controls="v-pills-delete" aria-selected="false" v-show="paymentData">{{ $t('console.extra') }}</a>
            </div>

            <div class="tab-content w-100" id="v-pills-tabContent">
              <div class="tab-pane fade show active" id="payment-method-info" role="tabpanel"
                aria-labelledby="v-pills-home-tab">
                <div class="px-5 pt-3">

                  <div v-if="paymentData">
                    <h4>{{ t(pre + 'title1') }}</h4>
                    <table class="table  table-bordered">
                      <tbody>
                        <tr>
                          <td>{{ t(pre + 'cardNumber') }}: </td>
                          <td> **** **** **** {{ paymentData.last4 }} </td>
                        </tr>
                        <tr>
                          <td>{{ t(pre + 'created') }}:</td>
                          <td class="text-capitalize">
                            {{ $filters.formatTime(paymentData.created * 1000) }}</td>
                        </tr>
                        <tr>
                          <td>{{ t(pre + 'expire') }}:</td>
                          <td class="text-capitalize">
                            {{ `${paymentData.exp_year}.${paymentData.exp_month}` }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="text-center mt-5" v-else-if="!createing">
                    <button class="btn btn-secondary" v-if="!loading" @click="createMethod">
                      {{ t(pre + 'createMethodTip') }}
                    </button>
                  </div>

                  <div class="text-center" v-if="loading">
                    <div class="spinner-grow text-light" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>


                  <div v-show="createing">
                    <div id="payment-method-element">
                      <!-- Elements will create form elements here -->
                    </div>

                    <div id="stripe-payment-method-errors" class="text-danger" role="alert"></div>
                    <div id="stripe-payment-method-error-message" class="text-danger my-2" role="alert" ref="errorMsg">
                    </div>
                    <div id="stripe-payment-method-success-message" class="text-success my-2" role="alert"></div>

                    <div class="text-end mt-5">
                      <button class="btn border me-2" @click="createing = false"> {{ t('cancel') }}</button>
                      <button class="btn btn-primary" @click="confirmSetup"
                        :disabled="confirmButtonDisabled || submitting">
                        <Loading sm v-if="submitting" />
                        {{ t('submit') }}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
              <div class="tab-pane fade" id="payment-method-delete" role="tabpanel" aria-labelledby="v-pills-delete-tab">

                <div class="px-5 pt-3">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">
                      <i class="bi bi-exclamation-triangle-fill"></i>
                      {{ $t(pre + 'deleteQ') }}?
                    </h4>
                    <b> {{ t(pre + 'deleteTip') }}</b>

                    <p>{{ $t(pre + 'cardNumber') }}: **** **** **** {{ paymentData?.last4 }}</p>
                    <hr />
                    <p class="mb-0">{{ $t('undone') }}</p>
                  </div>

                  <div class="mt-5 text-end">
                    <button type="button" class="btn btn-danger" @click="showConfirmDelete = true" :disabled="loading"
                      v-show="!showConfirmDelete">
                      {{ $t('fileTree.delete') }}
                    </button>
                    <button type="button" class="btn btn-secondary me-2" @click="showConfirmDelete = false"
                      :disabled="loading" v-show="showConfirmDelete">
                      {{ $t('cancel') }}
                    </button>
                    <button type="button" class="btn btn-danger" @click="deletePaymentMethod" :disabled="submitting"
                      v-show="showConfirmDelete">
                      <Loading :sm="true" v-if="submitting" />
                      {{ t(pre + 'confirmDelete') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { reactive, toRefs, inject, computed, watch } from 'vue'
import { useStore } from "vuex"
import { useI18n } from "vue-i18n"
const config = getConfig();
export default {
  name: '',
  props: [],
  setup() {
    const { $axios, $notify } = inject('$')
    const store = useStore()
    const { t, } = useI18n()
    const v = reactive({
      loading: false,
      CN: store.state.production_cn,
      tobeEdit: {},
      loading: false,
      stripe: null,
      createing: false,
      submitting: false,
      showConfirmDelete: false,
      confirmButtonDisabled: true,
      errorMsg: null,
      paymentMethodInfo: null,
      retryTime: 0,
      closeSettings: null,
    })

    watch(() => v.createing, (val) => {
      if (!val) {
        cancelCreate()
      }
    })

    const paymentData = computed(() => {
      return store.state.paymentMethod

    })

    async function createMethod() {
      v.stripe = Stripe(config.stripe.publicKey);
      let secret
      try {
        v.loading = true
        const res = await $axios.post(`/api/v1/stripePaymentMethod`,);
        secret = res.data.client_secret
        v.createing = true

      } catch (error) {
        console.error(error);
      } finally {

      }
      const appearance = { theme: store.state.theme == 'dark' ? "night" : "light", labels: 'floating' };
      const options = {
        clientSecret: secret,
        // Fully customizable with appearance API.
        appearance,
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
      const elements = v.stripe.elements(options);
      v.elements = elements

      // Create and mount the Payment Element
      const paymentElement = elements.create('payment');
      v.paymentElement = paymentElement;
      paymentElement.mount('#payment-method-element');

      v.loading = false

      paymentElement.on('change', function (event) {
        const displayError = document.getElementById('stripe-payment-method-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
          v.confirmButtonDisabled = !event.complete;
        }
      });

    }


    async function confirmSetup() {
      v.submitting = true;
      const elements = v.elements
      v.errorMsg.textContent = ''
      const { error } = await v.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',

      });

      const Container = document.querySelector('#stripe-payment-method-success-message');
      if (error) {
        v.submitting = false;
        const messageContainer = document.querySelector('#stripe-payment-method-error-message');
        messageContainer.textContent = error.message;
      } else {
        setTimeout(() => {
          getPaymentMethod(true)
        }, 1000)
      }
    }
    getPaymentMethod()
    async function getPaymentMethod(retry) {
      try {
        const res = await $axios.get('/api/v1/stripePaymentMethod')
        if (res.data.id) {
          store.commit("SET_PAYMENT_METHOD", res.data)
          v.createing = false
          v.submitting = false

          if (store.state.reSub) {
            document.getElementById('myPlanBtn').click()
            store.commit("SET_RE_SUB", null)
          }
        } else if (retry && v.retryTime <= 10) {
          setTimeout(() => {
            getPaymentMethod()
          }, 1000)
        } else if (retry && v.retryTime > 10) {
          $notify({ type: error, text: t('') })
        }
      } catch (error) {
        console.error(error);
      }
    }

    async function deletePaymentMethod() {
      v.submitting = true
      try {
        await $axios.delete('/api/v1/stripePaymentMethod')
        $notify({ type: 'success', text: t('notify.reqSuccess') })
        store.commit("SET_PAYMENT_METHOD", null)
        v.paymentMethodInfo.click()
      } catch (error) {
        console.error(error);
      } finally {
        v.submitting = false
        v.showConfirmDelete = false
      }
    }

    function cancelCreate() {
      const messageContainer = document.querySelector('#stripe-payment-method-error-message');
      messageContainer.textContent = "";
    }

    function handleClose(params) {
      v.showConfirmDelete = false; v.createing = false;
      let fromPricing = localStorage.getItem('fromPricing')
      if (fromPricing) {
        document.getElementById('UpDownBtn').click()
        localStorage.removeItem('fromPricing')
      }
    }

    return {
      ...toRefs(v), t, pre: "paymentMethod.", paymentData, createMethod, confirmSetup, deletePaymentMethod, handleClose
    }
  }
}
</script>
<style scoped></style>
export default {
  header: {
    myPlans: "My plans",
    Upgrade: "Upgrade",
  },
  downloadModal: {
    title: "About node client",
    download: "Download FilePipe-node",
    history: "History versions",
    title2: "Linux、macOS:",
    bit: "-BIT",
    p1: "If you are running Linux, macOS, or another Unix-like operating system and want to download the FilePipe Node, please run the following command in your terminal and follow the instructions on the screen.",
    p2: "If you are running Windows, to use the FilePipe Node, please download the corresponding client based on your operating system.",
  },
  subModal: {
    title: "Subscription information",
    tableTitle: "Your subscription",
    th1: "Plan",
    th2: "Period",
    th3: "Pay method",
    time1: "You have joined",
    time2: "Next renewal at",
    time3: "Cancel time",
    reSubscribe: "Re-subscribe",
    confirm: "Click again to confirm cancel",
    changeTip: "The package you subscribed to has new changes",
    oldPrice: "Old price",
    newPrice: "New price",
    effectiveDate: "Start date",
    updateUrlText: "Please click here to confirm with PayPal!",
  },
  home: {
    title1: "An incredibly powerful",
    title2: "File transfer system",
    slogan: "making multi-device file transfers unprecedentedly simple.",
    button1: "Try it now",
    backTop: "Back to top",
    download: {
      title: "Simple installation and startup",
      1: "Create group and get API key",
      2: "Download and run our client at you nodes",
      3: "Manage transfers at the dashboard",
    },
    features: {
      title1: "Let your files flow as needed",
      subtitle11: "You only need to set once,",
      subtitle12:
        "and the files in the directory will automatically leave the directory you specified.",
      q1: "Do you have file sharing issues between multiple devices?",
      q2: "Do you have multiple disk file transfer issues?",
      q3: "Our program will automatically transfer files to the path you set according to your settings.",
      q4: "Even different folders, different hard disks and even different operating systems.",
      title2: "Working mode fully automatic",
      subtitle21: "It only needs to be initialized once,",
      subtitle22: "and the whole process will be automated after setting.",
      subhead1: "Automatically join a group!",
      info1:
        "Use the group key to start your node, and the node will automatically join your group without adding it manually.",
      subhead2: "Manage your hard disk cluster!",
      info2:
        "It is convenient to manage a large number of hard disk clusters by attaching the hard disk to the node program, which is fast, convenient and clear.",
      subhead3: "Automatic flow of documents!",
      info3:
        "You only need to perform the connection settings once. When files appear in the specified directory, they will automatically flow to the target directory, even to another device under the LAN.",
      title3: "The accompanying web console",
      subtitle3:
        "Window based node management, so nodes are clear at a glance.",
      console: {
        title1: "Create group freely",
        info1:
          "All nodes are more grouped for easy management without interfering with each other. ",
        guide: "Create your own group",
        title2: "Rich content, clear structure",
        info2:
          "Each node will display the disk and path it has added, with a clear structure at a glance.",
        title3: "Clear status display",
        info3:
          "Clearly display the working status of each path under the node, such as",
        title4: "Keep both ends synchronized",
        info4:
          "The web console and nodes are synchronized in real-time, allowing for the creation of new paths or connections on the web at any time.",
        title5: "Real time file upload list",
        info5:
          "The sidebar of the console page will display real-time information about the file being uploaded. \n Including file size, upload speed, and various detailed information.",
        title6: "Manage nodes like Windows",
        info6:
          "Feel free to zoom in and out, and place it according to your preference.",
        title7: "About nodes",
        info7:
          " The node will display the IP address of the corresponding machine and can also be customized for easy management.",
        title8: "About disks",
        info8:
          " After adding a path, it will automatically find the location of the hard disk, as well as real-time capacity display and high temperature prompt.",
        title9: "About paths",
        info9:
          "path is the folder path where files are stored, which can already exist on your machine or create a path at will, even if it does not exist before.",
      },
      title4: "Simple configuration and startup",
      subtitle4:
        "Simply configure to start the client, depoly once, remember forever.",
      example: {
        title1: "The configuration file is simple and clear",
        title2: "Complete the configuration to start",
        info1:
          "Without any other requirements or operations, it can be started directly, and running in the background will not disturb the normal operation of other programs.",
        info2:
          "After configuration is completed, enter the terminal in the directory where the node is located:",
        info3: "If you are Windows, you can also double-click to open it.",
        linux: "In the Linux system, enter:",
        macOS: "In the macOs system, enter:",
        guide: "Don't have a client program yet? ",
        download: "Download Now.",
      },
      title5: "All kinds of benefits",
      subtitle5:
        "FilePipe can making multi-device file transfers unprecedentedly simple.",
      kinds: {
        title1: "Compatible various systems",
        info1:
          "Including Windows, Mac and Linux, various systems have corresponding client programs, and the web terminal can control and view your file transfer in real time.",
        title2: "The accompanying web console",
        info2:
          "The web console can conveniently manage all nodes and the creation and management of direct file transfer pipelines for each node.",
        title3: "No registration required",
        info3:
          "Directly login using a third party such as Google, GitHub, without the need to register a separate account,convenient and fast.",
        title4: "Security by default, always",
        info4:
          "The transmission of files is always in your LAN and will not connect to any network. Don't worry about file leakage.",
      },
      ready: "Ready to using FilePipe?",
      or: "or",
      downloadText:
        "Download the FilePipe client program to start your file management.",
      downloadC: "Donwload client",
      downloadAll: "All historical versions",
      subscribeT: "Use more services through subscription",
      subscribeinfo:
        "We have a variety of services for subscription and support multiple payment methods such as Stripe and PayPal.",
      unsubscribe: "Unsubscribe any time",
    },
    footer: {
      company: "Company",
      policy: "Privacy Policy",
      terms: "Terms of Service",
      ticket: "Ticket",
      termsTitle: "FilePipe Terms of Service",
    },
  },
  pricing: {
    monthly: "Monthly",
    yearly: "Yearly",
    current: "Current",
    pre: "pre",
    month: "month",
    year: "year",
    reHome: "Return home",
    confirming: "Confirming",
    features: "Features",
    expired: "expired",
    invitationCode: "Invitation code",
    invitePlacehold: "Please enter the invitation code",
    inviteExplain:
      "You can enter an invitation code obtained from elsewhere. Using an invitation code can benefit the other party. You can leave it blank if you don't have one.",
    udModal: {
      title: "Subscription",
      head: "Switch subscription package:",
      text: "After switching, you need to pay",
      tip: "Are you sure want to continue",
      udS: "Switch subscription succeeded",
      willToHome: "About to return to the home page",
    },
    payModal: {
      title: "Payment",
      head: "Buying package",
      needToPay: "Need to pay",
      text: "Stripe has detected that you have retained balance without payment and has automatically renewed the contract for you",
      paying: "Paying",
      pay: "Pay",
      paySuccess: "Success! Payment received. About to return to the home page",
      next: "Next",
      saveMethodSuccess: "Payment method saved successfully. Submitting order",
    },
  },
  notFount: {
    title: "Not fount",
    info: "Looks like this page has gone missing",
    goback: "Go back",
  },
  login: "Login",
  logout: "Logout",
  logining: "Logging in",
  loginTitle: "Login to",
  docs: "Docs",
  cancel: "Cancel",
  save: "Save",
  saved: "Saved",
  sure: "Sure",
  submit: "Submit",
  confirm: "Confirm",
  subscribe: "Subscribe",
  ok: "Ok",
  working: "Working",
  offline: "Offline",
  waiting: "Waiting",
  error: "Error",
  etc: "etc",
  undone: "This action can't be undone.",
  copied: "Copied",
  copy: "Copy to clipboard",
  empty: "Empty",
  console: {
    text: "Console",
    group: "group",
    node: "node",
    path: "path",
    name: "name",
    download: "Download client",
    createToStart: "Create a group to start",
    newGroup: "New group",
    addNode: "Add nodes",
    nodes: "Nodes",
    paths: "Paths",
    pipes: "Pipes",
    createNewG: "Create group",
    groupName: "Group name",
    createG: "Create group",
    groupSet: "Group settings",
    general: "General",
    apikey: "API Key",
    member: "Members",
    extra: "Extra",
    groupId: "Group id",
    rfapikey: "Refresh API Key",
    deleteGroupQ: "Are you sure to delete this group",
    dgroup: "Delete group",
    confirmDeleteG: "Click again to confirm deletion",
  },
  nodes: {
    IP: "IP",
    name: "Name",
    mac: "Mac",
    mountP: "Mountpoint",
    backC: "Back to center",
    resetL: "Reset layout",
    fromIp: "From ip",
    from: "From",
    to: "To",
    nameModal: {
      title: "Set node name",
      label: "New name with",
    },
    deletePModal: {
      title: "Delete mount path",
      tip: "Are you sure to delete this mount path?",
      fromMou: "From mountpoint",
      fromIp: "From ip",
      delSource: "Delete source file (please choose carefully!)",
    },
    cPathModal: {
      title: "Create path",
      label: "New path with",
      cPath: "Create path",
    },
    delNodeModal: {
      title: "Delete node",
      tip: "Are you sure to delete this node?",
      text: "The delete operation will clear the related data of this node, but if you rerun the client program on the node, the node will reappear here.",
    },
    delDiskModal: {
      title: "Delete all mount paths under this disk",
      tip: "Are you sure to delete",
      strong: "all mount paths on this disk",
    },
    resetLModal: {
      title: "Reset layout",
      tip: "Are you sure you want to reset the layout?",
    },
    mfModal: {
      title: "Create a transfer pipe",
      title1: "Establish file transfer between paths",
      title2: "Sync mode",
      label1: "Relative sync",
      formText1: "It just transfers files from source to destination",
      label2: "Absolute sync",
      formText2:
        "It will keep files absolutely the same between source and destination, extra files at destination will be removed",
      title3: "Force overwrite",
      label3: "Forcefully overwrite the files exist already",
      title4: "Validate md5",
      label4: "Do you want to verify md5",
      title5: "Filters",
      title6: "Delete pipe",
      formText5:
        "Use wildcard pattern to filter files you want to transfer excluding or including. Set no filter for “Exclude” if you want to transfer all.",
      label5: "Exclude",
      label6: "Include",
      label7: "Input wildcard filters",
      alertDanger:
        "You have to set available filters to include, otherwise nothing will be transferred.",
      placeHolder: "E.g. *.txt",
    },
    pipeInfoModal: {
      title: "Transfer pipe",
      delPipe: "Delete pipe",
      details: "Details",
      speed: "Speed",
      log: "Log",
      autoScroll: "Auto Scroll",
    },
    delPipeModal: {
      title: "Delete pipe",
      tip: " Are you sure to delete this pipe?",
    },
  },
  node: {
    state: "State",
    version: "Version",
    newPath: "New path",
    setNodeName: "Set node name",
    deleteNode: "Delete node",
    tempatureTip: "Hard disk temperature up to {d}℃",
    deleteAll: "Delete all mount paths on this disk",
    delPath: "Delete mount path",
    total: "Total",
    free: "Free",
    readOnly: "Read only",
    readOnlyTip: "This path can only be read but not written",
  },
  wildcardTable: {
    td1: "Character",
    td2: "Description",
    td3: "Example",
    p1: "matches character c (c != '\\', '-', ']')",
    p2: "matches any sequence of non-Separator characters",
    finds: "finds",
    and: "and",
    butnot: "but not",
    or: "or",
    p3: "matches any single non-Separator character",
    p4: "matches characters within the brackets.",
    p5: "matches character c",
    p6: "matches character c for lo <= c <= hi",
  },
  notify: {
    unableDown: "Unable to download temporarily",
    netError: "Network error, please try again later",
    reqSuccess: "Request success",
    upSuccess: "Update succeed",
    pleaseWait: "Request sented, please wait for the results",
    invalid: "Invalid operation",
    paypalNoPro:
      "Paypal service cannot be provided,please choose another method",
    createdSubSuccess: "You have successfully created subscription",
    unableLoadPaypal: "Unable to load PayPal, please try again later",
    groupnameExists: "The group name already exists",
    loginAgain: "Please login again",
    loginFirst: "Please login first",
    noline: "Unable to connect to the target path. Please check its status.",
  },
  tickets: "Tickets",
  ticket: {
    ticket: "Tickets",
    empty: "Empty",
    subTitle:
      "Create a ticket to provide feedback on issues and seek assistance",
    open: "Open",
    close: "Close",
    all: "All",
    cancel: "Cancel",
    submit: "Submit",
    sure: "Sure",
    save: "Save",
    created_at: "Created at",
    updated_at: "Updated at",
    undone: "This action can't be undone.",
    title: "Title",
    createModal: {
      createBtn: "Create Ticket",
      placeHolder1: "Please enter title",
      placeHolder2: "Please describe the problem you have encountered",
      formText:
        "For easier communication with the staff, we recommend using English as much as possible.",
      title: "Title",
      category: "Category",
      content: "Content",
      attachment: "Attachment",
      upload: "Upload",
    },
    putModal: {
      title: "Edit Ticket",
    },
    delModal: {
      title: "Delete Ticket",
      tip: "Are you sure to delete this ticket?",
    },
    closeModal: {
      title: "Close Ticket",
      tip: "Are you sure to close this ticket?",
    },
    commented: "Commented",
    noComment: "No comment",
    yourComment: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: "Delete comment",
  },
  createdAt: "Created at",
  updatedAt: "Update at",
  close: "Close",
  open: "Open",
  all: "All",
  title: "Title",
  category: "Category",
  content: "Content",
  comment: {
    commented: "commented",
    title: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: {
      title: "Delete comment",
      tip: "Are you sure delete this comment",
    },
  },
  balance: {
    balance: "Balance",
    order_id: "Order Id",
    type: "Type",
    income: "Income",
    outgo: "Outgo",
    created_at: "Create Time",
  },
  userInfo: {
    title: "UserInfo",
    user: "User",
    username: "Username",
    copied: "Copied to the clipboard",
    invitation_code: "Invitation code",
    used_inv_code: "Used invitation code",
    code_describe:
      "When other users use your invitation code when purchasing a subscription, you will receive a discount when purchasing a subscription",
  },
  fileTree: {
    emptyFolder: "Empty folder",
    newFile: "New file",
    copy: "Copy",
    paste: "Paste",
    newFilePlaceholder: "Please enter file name",
    newFloder: "New folder",
    newFloderPlaceholder: "Please enter folder name",
    delete: "Delete",
    deletePrompt: "Are your sure delete this file or path",
    errorName: "Wrong file name",
    sameName: "Duplicate file name",
    sameFolderName: "Duplicate folder name",
    errorfloder: "Wrong folder name",
  },
  oneTimeSync: {
    modalTitle: "One time Sync",
    title1: "Establish a one-time quick transfer",
  },
  subscriptions: {
    title: "Historical subscription",
    planname: "Plan Name",
    amount: "Amount",
    status: "Status",
    expired: "Expired",
    notExpired: "Not Expired",
    createtime: "Start Time",
    stopTime: "End Time",
    PayMethod: "Pay method",
  },
  paymentMethod: {
    title: "Payment Method",
    title1: "Stripe default payment method",
    createMethodTip:
      "There is currently no default payment method. Click to create one",
    cardNumber: "Card Number",
    created: "Created Time",
    expire: "Expiration time",
    deleteQ: "Are you sure you want to delete this payment method",
    deleteTip:
      "Deleting the default payment method may result in failed deductions for future subscriptions, so please proceed with caution.",
    confirmDelete: "Click again to confirm deletion of payment method",
  },
  groupMember: {
    title: "Group Members",
    invite: "Invite",
    remove: "Remove",
    removePrompt: "Are you sure remove this member",
    ownerPrompt:
      "Are you sure to transfer the ownership of the Group to this member{name}?",
    changeOwner: "Click Transfer Ownership Again",
    setOwner: "Set Owner",
    name: "Name",
    owner: "Owner",
    joined: "Join Time",
  },
  InviteMember: {
    title: "Invite Member",
    searchlable: "Search Users",
    searchTip: "Support username, email, or user ID.",
    invited: "Invited",
    member: "Member",
  },
  Invitation: {
    title:
      "You have been invited to join the group ({groupName}) created by {name} {email}.  Please click on the button to accept the invitation.",
    accept: "Accept invitation",
    describe: "Joining a group allows for access to",
    agreed: "Accepted",
    expired: "Expired",
  },
  yes: "True",
  no: "False",
  notification: {
    title: "Notifications",
  },
};

export default {
  header: {
    myPlans: "Meine Pläne",
    Upgrade: "Upgrade",
  },
  downloadModal: {
    title: "Über den Node-Client",
    download: "FilePipe herunterladen",
    history: "Versionsverlauf",
    title2: "Linux、macOS:",
    bit: "-BIT",
    p1: "Wenn Sie Linux, macOS oder ein anderes Unix-ähnliches Betriebssystem verwenden und den FilePipe Node herunterladen möchten, führen Sie bitte den folgenden Befehl in Ihrem Terminal aus und folgen Sie den Anweisungen auf dem Bildschirm.",
    p2: "Wenn Sie Windows verwenden, um den FilePipe Node zu nutzen, laden Sie bitte den entsprechenden Client basierend auf Ihrem Betriebssystem herunter.",
  },
  subModal: {
    title: "Abonnementinformationen",
    tableTitle: "Ihr Abonnement",
    th1: "Plan",
    th2: "Zeitraum",
    th3: "Zahlungsmethode",
    time1: "Sie sind beigetreten am",
    time2: "Nächste Verlängerung am",
    time3: "Kündigungszeit",
    reSubscribe: "Erneut abonnieren",
    confirm: "Klicken Sie erneut, um die Kündigung zu bestätigen",
    changeTip: "Das von Ihnen abonnierte Paket hat neue Änderungen",
    oldPrice: "Alter Preis",
    newPrice: "Neuer Preis",
    effectiveDate: "Startdatum",
    updateUrlText: "Bitte klicken Sie hier, um mit PayPal zu bestätigen!",
  },
  home: {
    title1: "Ein unglaublich leistungsstarkes",
    title2: "Dateiübertragungssystem",
    slogan: "macht mehrgeräte Dateiübertragungen unvergleichlich einfach.",
    button1: "Jetzt ausprobieren",
    backTop: "Zurück nach oben",
    download: {
      title: "Einfache Installation und Start",
      1: "Erstellen Sie eine Gruppe und erhalten Sie einen API-Schlüssel",
      2: "Laden Sie unseren Client auf Ihren Knoten herunter und führen Sie ihn aus",
      3: "Verwalten Sie die Übertragungen im Dashboard",
    },
    features: {
      title1: "Lassen Sie Ihre Dateien frei fließen",
      subtitle11: "Sie müssen es nur einmal einrichten,",
      subtitle12:
        "und die Dateien im Verzeichnis verlassen automatisch das von Ihnen angegebene Verzeichnis.",
      q1: "Haben Sie Probleme beim Datei-Sharing zwischen mehreren Geräten?",
      q2: "Haben Sie Probleme mit der Übertragung von Dateien auf mehrere Festplatten?",
      q3: "Unser Programm überträgt Dateien automatisch auf den von Ihnen festgelegten Pfad entsprechend Ihren Einstellungen.",
      q4: "Selbst unterschiedliche Ordner, unterschiedliche Festplatten und sogar unterschiedliche Betriebssysteme.",
      title2: "Vollautomatischer Arbeitsmodus",
      subtitle21: "Es muss nur einmal initialisiert werden,",
      subtitle22:
        "und der gesamte Prozess wird nach dem Einrichten automatisiert.",
      subhead1: "Automatisches Beitreten zu einer Gruppe!",
      info1:
        "Verwenden Sie den Gruppenschlüssel, um Ihren Knoten zu starten, und der Knoten wird automatisch Ihrer Gruppe beitreten, ohne dass er manuell hinzugefügt werden muss.",
      subhead2: "Verwalten Sie Ihr Festplatten-Cluster!",
      info2:
        "Es ist bequem, eine große Anzahl von Festplatten-Clustern durch Anschließen der Festplatte an das Knotenprogramm zu verwalten, was schnell, bequem und übersichtlich ist.",
      subhead3: "Automatischer Dokumentenfluss!",
      info3:
        "Sie müssen die Verbindungseinstellungen nur einmal durchführen. Wenn Dateien im angegebenen Verzeichnis erscheinen, fließen sie automatisch in das Zielverzeichnis, sogar zu einem anderen Gerät im LAN.",
      title3: "Die begleitende Webkonsole",
      subtitle3:
        "Windows-basierte Knotenverwaltung, so dass Knoten auf einen Blick klar sind.",
      console: {
        title1: "Frei Gruppen erstellen",
        info1:
          "Alle Knoten sind übersichtlich gruppiert und einfach zu verwalten, ohne sich gegenseitig zu beeinflussen.",
        guide: "Erstellen Sie Ihre eigene Gruppe",
        title2: "Vielfältiger Inhalt, klare Struktur",
        info2:
          "Jeder Knoten zeigt die Festplatten und Pfade an, die er hinzugefügt hat, mit einer klaren Struktur auf einen Blick.",
        title3: "Klare Statusanzeige",
        info3:
          "Die Arbeitsstatus jedes Pfads unter dem Knoten wird deutlich angezeigt, z.B.",
        title4: "Beide Enden synchronisiert halten",
        info4:
          "Die Webkonsole und Knoten sind in Echtzeit synchronisiert, sodass jederzeit neue Pfade oder Verbindungen auf der Webseite erstellt werden können.",
        title5: "Echtzeit-Upload-Liste",
        info5:
          "Die Seitenleiste der Konsolenseite zeigt Echtzeitinformationen zum hochgeladenen File an. Darunter fallen Größe, Upload-Geschwindigkeit und verschiedene Details.",
        title6: "Knoten wie in Windows verwalten",
        info6:
          "Zoomen Sie beliebig und positionieren Sie es nach Ihren Wünschen.",
        title7: "Über Knoten",
        info7:
          "Der Knoten zeigt die IP-Adresse der entsprechenden Maschine an und kann auch benutzerdefiniert werden, um die Verwaltung zu erleichtern.",
        title8: "Über Festplatten",
        info8:
          "Nachdem ein Pfad hinzugefügt wurde, wird automatisch der Standort der Festplatte gefunden, sowie eine Echtzeitanzeige der Kapazität und eine hohe Temperaturwarnung.",
        title9: "Über Pfade",
        info9:
          "Pfad ist der Ordnerpfad, in dem Dateien gespeichert werden, der bereits auf Ihrem Computer vorhanden sein kann oder frei erstellt werden kann, auch wenn er zuvor nicht existierte.",
      },
      title4: "Einfache Konfiguration und Start",
      subtitle4:
        "Konfigurieren Sie einfach, um den Client zu starten, einmal bereitgestellt, immer merken.",
      example: {
        title1: "Die Konfigurationsdatei ist einfach und klar",
        title2: "Konfiguration abschließen, um zu starten",
        info1:
          "Ohne weitere Anforderungen oder Operationen kann es direkt gestartet werden und läuft im Hintergrund, ohne den normalen Betrieb anderer Programme zu stören.",
        info2:
          "Nach Abschluss der Konfiguration geben Sie im Terminal im Verzeichnis des Knotens ein:",
        info3:
          "Wenn Sie Windows verwenden, können Sie es auch doppelklicken, um es zu öffnen.",
        linux: "Geben Sie im Linux-System ein:",
        macOS: "Geben Sie im macOS-System ein:",
        guide: "Haben Sie noch kein Client-Programm?",
        download: "Jetzt herunterladen.",
      },
      title5: "Alle Arten von Vorteilen",
      subtitle5:
        "FilePipe macht den Dateitransfer zwischen mehreren Geräten unglaublich einfach.",
      kinds: {
        title1: "Kompatibel mit verschiedenen Systemen",
        info1:
          "Windows, Mac und Linux werden unterstützt. Es gibt entsprechende Client-Programme für verschiedene Systeme und das Webterminal kann Ihren Dateitransfer in Echtzeit steuern und anzeigen.",
        title2: "Die begleitende Web-Konsole",
        info2:
          "Die Web-Konsole kann alle Knoten bequem verwalten und die Erstellung und Verwaltung direkter Dateiübertragungspipelines für jeden Knoten durchführen.",
        title3: "Keine Registrierung erforderlich",
        info3:
          "Melden Sie sich direkt über Drittanbieter wie Google und GitHub an, ohne dass ein separater Account registriert werden muss. Bequem und schnell.",
        title4: "Standardmäßig sicher",
        info4:
          "Die Übertragung von Dateien erfolgt immer in Ihrem LAN und es wird keine Verbindung zu einem Netzwerk hergestellt. Machen Sie sich keine Sorgen über Datenlecks.",
      },
      ready: "Bereit, FilePipe zu nutzen?",
      or: "oder",
      downloadText:
        "Laden Sie das FilePipe-Client-Programm herunter, um Ihre Dateiverwaltung zu starten.",
      downloadC: "Client herunterladen",
      downloadAll: "Alle historischen Versionen",
      subscribeT: "Durch Abonnement mehr Dienste nutzen",
      subscribeinfo:
        "Wir haben eine Vielzahl von Abonnementdiensten und unterstützen mehrere Zahlungsmethoden wie Stripe und PayPal.",
      unsubscribe: "Jederzeit abbestellen",
    },
    footer: {
      company: "Unternehmen",
      policy: "Datenschutzrichtlinie",
      terms: "Nutzungsbedingungen",
      termsTitle: "FilePipe Nutzungsbedingungen",
    },
  },
  pricing: {
    monthly: "Monatlich",
    yearly: "Jährlich",
    current: "Aktuell",
    pre: "Vorherig",
    month: "Monat",
    year: "Jahr",
    reHome: "Zurück zur Startseite",
    confirming: "Bestätigen",
    features: "Merkmale",
    expired: "abgelaufen",
    invitationCode: "Einladungscode",
    invitePlacehold: "Bitte geben Sie den Einladungscode ein",
    inviteExplain:
      "Sie können einen Einladungscode eingeben, den Sie anderswo erhalten haben. Die Verwendung eines Einladungscodes kann der anderen Partei zugutekommen. Sie können ihn leer lassen, wenn Sie keinen haben",
    udModal: {
      title: "Abonnement",
      head: "Abonnement-Paket wechseln:",
      text: "Nach dem Wechsel müssen Sie bezahlen",
      tip: "Sind Sie sicher, dass Sie fortfahren möchten?",
      udS: "Abonnement erfolgreich gewechselt",
      willToHome: "Zurück zur Startseite",
    },
    payModal: {
      title: "Zahlung",
      head: "Paketkauf",
      needToPay: "Zu zahlender Betrag",
      text: "Stripe hat festgestellt, dass Sie einen ausstehenden Betrag haben und hat den Vertrag automatisch für Sie verlängert.",
      paying: "Bezahlen",
      pay: "Bezahlen",
      paySuccess: "Erfolgreich! Zahlung erhalten. Zurück zur Startseite.",
      next: "Nächster Schritt",
      saveMethodSuccess:
        "Zahlungsmethode erfolgreich gespeichert. Bestellung wird übermittelt.",
    },
  },
  notFount: {
    title: "Nicht gefunden",
    info: "Es sieht aus, als ob diese Seite verloren gegangen ist",
    goback: "Zurück",
  },
  login: "Einloggen",
  logout: "Abmelden",
  logining: "Melde mich an",
  loginTitle: "Einloggen bei",
  docs: "Dokumente",
  cancel: "Abbrechen",
  save: "Speichern",
  saved: "gespeichert",
  sure: "Sicher",
  submit: "Absenden",
  confirm: "Bestätigen",
  subscribe: "Abonnieren",
  ok: "Ok",
  working: "Arbeiten",
  offline: "Offline",
  error: "Fehler",
  waiting: "Warten",
  etc: "etc",
  undone: "Diese Aktion kann nicht rückgängig gemacht werden.",
  copied: "Kopiert",
  copy: "Code in die Zwischenablage kopieren",
  empty: "leer",
  console: {
    text: "Konsole",
    group: "Gruppe",
    node: "Knoten",
    path: "Pfad",
    name: "Name",
    createToStart: "Erstellen Sie eine Gruppe, um zu beginnen",
    newGroup: "Neue Gruppe",
    addNode: "Knoten hinzufügen",
    nodes: "Knoten",
    paths: "Pfade",
    pipes: "Pipes",
    createNewG: "Neue Gruppe erstellen",
    groupName: "Gruppenname",
    createG: "Gruppe erstellen",
    groupSet: "Gruppeneinstellungen",
    general: "Allgemein",
    apikey: "API Key",
    member: "Mitglied",
    extra: "Extra",
    groupId: "Gruppen-ID",
    rfapikey: "API Key aktualisieren",
    deleteGroupQ: "Sind Sie sicher, dass Sie diese Gruppe löschen möchten",
    dgroup: "Gruppe löschen",
    confirmDeleteG: "Klicken Sie erneut, um die Löschung zu bestätigen",
  },
  nodes: {
    IP: "IP",
    name: "Name",
    mac: "Mac",
    mountP: "Mountpoint",
    backC: "Zurück zum Zentrum",
    resetL: "Layout zurücksetzen",
    fromIp: "Von IP",
    from: "Von",
    to: "Nach",
    nameModal: {
      title: "Name des Knotens festlegen",
      label: "Neuer Name mit",
    },
    deletePModal: {
      title: "Lösche Mount-Pfad",
      tip: "Sind Sie sicher, diesen Mount-Pfad zu löschen?",
      fromMou: "Von Mountpoint",
      fromIp: "Von IP",
      delSource: "Quelldatei löschen (bitte sorgfältig auswählen!)",
    },
    cPathModal: {
      title: "Neuen Pfad erstellen",
      label: "Neuer Pfad mit",
      cPath: "Pfad erstellen",
    },
    delNodeModal: {
      title: "Knoten löschen",
      tip: "Sind Sie sicher, dass Sie diesen Knoten löschen möchten?",
      text: "Die Löschung löscht die zugehörigen Daten dieses Knotens, aber wenn Sie das Client-Programm erneut auf dem Knoten ausführen, wird der Knoten hier wieder auftauchen.",
    },
    delDiskModal: {
      title: "Alle Einhängepunkte unter diesem Datenträger löschen",
      tip: "Sind Sie sicher, dass Sie löschen möchten",
      strong: "alle Einhängepunkte auf diesem Datenträger",
    },
    resetLModal: {
      title: "Layout zurücksetzen",
      tip: "Sind Sie sicher, dass Sie das Layout zurücksetzen möchten?",
    },
    mfModal: {
      title: "Erstellen Sie eine Übertragungsröhre",
      title1: "Dateiübertragung zwischen Pfaden herstellen",
      title2: "Sync-Modus",
      label1: "Relative Synchronisation",
      formText1: "Es werden nur Dateien von der Quelle zum Ziel übertragen.",
      label2: "Absolute Synchronisation",
      formText2:
        "Es werden Dateien zwischen Quelle und Ziel absolut identisch gehalten. Zusätzliche Dateien am Ziel werden entfernt.",
      title3: "Erzwungenes Überschreiben",
      label3: "Bereits vorhandene Dateien erzwingend überschreiben",
      title4: "md5 überprüfen",
      label4: "Möchten Sie die md5 überprüfen?",
      title5: "Filter",
      title6: "Pipe löschen",
      formText5:
        "Verwenden Sie Wildcard-Muster, um Dateien, die Sie übertragen möchten, auszuschließen oder einzubeziehen. Legen Sie kein Filter fest, wenn Sie alle übertragen möchten.",
      label5: "Ausschließen",
      label6: "Einschließen",
      label7: "Wildcard-Filter eingeben",
      alertDanger:
        "Sie müssen verfügbare Filter zum Einbeziehen festlegen, da sonst nichts übertragen wird.",
      placeHolder: "z.B. *.txt",
    },
    pipeInfoModal: {
      title: "Übertragungsrohr",
      delPipe: "Rohr löschen",
      details: "Einzelheiten",
      speed: "Geschwindigkeit",
      log: "Protokoll",
      autoScroll: "Automatisches Scrollen",
    },
    delPipeModal: {
      title: "Pipe löschen",
      tip: "Sind Sie sicher, dass Sie diese Pipe löschen möchten?",
    },
  },
  node: {
    state: "Zustand",
    version: "Version",
    newPath: "Neuer Pfad",
    setNodeName: "Name des Knotens festlegen",
    deleteNode: "Knoten löschen",
    tempatureTip: "Festplattentemperatur bis zu {d}°C",
    deleteAll: "Alle Einhängepunkte unter diesem Datenträger löschen",
    delPath: "Löschen Sie den Mount-Pfad",
    total: "Gesamt",
    free: "Frei",
    readOnly: "Nur Lesen",
    readOnlyTip: "Dieser Pfad kann nur gelesen, aber nicht geschrieben werden",
  },
  wildcardTable: {
    td1: "Zeichen",
    td2: "Beschreibung",
    td3: "Beispiel",
    p1: "passt zum Zeichen c (c != '\\', '-', ']')",
    p2: "passt zu jeder Sequenz von Nicht-Separator-Zeichen",
    finds: "findet",
    and: "und",
    butnot: "aber nicht",
    or: "oder",
    p3: "passt zu einem einzelnen Nicht-Separator-Zeichen",
    p4: "Passt zu Zeichen innerhalb der Klammern.",
    p5: "passt zum Zeichen c",
    p6: "passt zum Zeichen c für lo <= c <= hi",
  },
  notify: {
    unableDown: "Vorübergehend nicht möglich herunterzuladen",
    netError: "Netzwerkfehler, bitte versuchen Sie es später erneut",
    reqSuccess: "Anfrage erfolgreich",
    upSuccess: "Aktualisierung erfolgreich",
    pleaseWait: "Anfrage gesendet, bitte warten Sie auf die Ergebnisse",
    invalid: "Ungültige Operation",
    paypalNoPro:
      "Paypal-Dienst kann nicht bereitgestellt werden, bitte wählen Sie eine andere Methode",
    createdSubSuccess: "Sie haben das Abonnement erfolgreich erstellt",
    unableLoadPaypal:
      "PayPal kann nicht geladen werden, bitte versuchen Sie es später erneut",
    groupnameExists: "Der Gruppenname existiert bereits",
    loginAgain: "Bitte loggen Sie sich erneut ein",
    loginFirst: "Bitte loggen Sie sich zuerst ein",
    noline:
      "Es ist nicht möglich, eine Verbindung zum Zielordner herzustellen. Bitte überprüfen Sie seinen Status.",
  },
  tickets: "Tickets",
  ticket: {
    ticket: "Tickets",
    empty: "Empty",
    subTitle:
      "Create a ticket to provide feedback on issues and seek assistance",
    open: "Open",
    close: "Close",
    all: "All",
    cancel: "Cancel",
    submit: "Submit",
    sure: "Sure",
    created_at: "Created at",
    updated_at: "Updated at",
    undone: "This action can't be undone.",
    title: "Title",
    createModal: {
      createBtn: "Create Ticket",
      placeHolder1: "Please enter title",
      placeHolder2: "Please describe the problem you have encountered",
      formText:
        "For easier communication with the staff, we recommend using English as much as possible.",
      title: "Title",
      category: "Category",
      content: "Content",
      attachment: "Attachment",
      upload: "Upload",
    },
    putModal: {
      title: "Edit Ticket",
    },
    delModal: {
      title: "Delete Ticket",
      tip: "Are you sure to delete this ticket?",
    },
    closeModal: {
      title: "Close Ticket",
      tip: "Are you sure to close this ticket?",
    },
    commented: "Commented",
    noComment: "No comment",
    yourComment: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: "Delete comment",
  },
  createdAt: "Created at",
  updatedAt: "Update at",
  close: "Close",
  open: "Open",
  all: "All",
  title: "Title",
  category: "Category",
  content: "Content",
  comment: {
    commented: "commented",
    title: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: {
      title: "Delete comment",
      tip: "Are you sure delete this comment",
    },
  },
  balance: {
    balance: "Guthaben",
    order_id: "Bestellnummer",
    type: "Art",
    income: "Einkommen",
    outgo: "Ausgaben",
    created_at: "Erstellungszeit",
  },
  userInfo: {
    title: "Benutzerinformationen",
    user: "Benutzer",
    username: "Benutzername",
    copied: "In die Zwischenablage kopiert",
    invitation_code: "Einladungscode",
    used_inv_code: "Verwendeter Einladungscode",
    code_describe:
      "Wenn andere Benutzer Ihren Einladungscode beim Kauf eines Abonnements verwenden, erhalten Sie einen Rabatt beim Kauf eines Abonnements",
  },
  fileTree: {
    emptyFolder: "Leerer Ordner",
    newFile: "Neue Datei",
    newFilePlaceholder: "Bitte Dateinamen eingeben",
    newFloder: "Neuer Ordner",
    newFloderPlaceholder: "Bitte Ordnernamen eingeben",
    delete: "Löschen",
    deletePrompt: "Sind Sie sicher, diese Datei oder diesen Pfad zu löschen",
    errorName: "Falscher Dateiname",
    errorfloder: "Falscher Ordnernamen",
    copy: "Kopieren",
    paste: "Einfügen",
    sameName: "Gleicher Dateiname",
    sameFolderName: "Gleicher Ordnernamen",
  },
  oneTimeSync: {
    modalTitle: "Einmalige Synchronisation",
    title1: "Einmalige schnelle Übertragung einrichten",
  },
  subscriptions: {
    title: "Historische Abonnements",
    planname: "Planname",
    amount: "Betrag",
    status: "Status",
    expired: "Ablauf",
    notExpired: "Nicht abgelaufen",
    createtime: "Startzeit",
    stopTime: "Endzeit",
    PayMethod: "Zahlungsmethode",
  },
  paymentMethod: {
    title: "Zahlungsmethode",
    title1: "Standard-Zahlungsmethode von Stripe",
    createMethodTip:
      "Derzeit ist keine Standard-Zahlungsmethode vorhanden. Klicken Sie, um eine zu erstellen.",
    cardNumber: "Kartennummer",
    created: "Erstellungszeitpunkt",
    expire: "Ablaufzeitpunkt",
    deleteQ: "Sind Sie sicher, dass Sie diese Zahlungsmethode löschen möchten?",
    deleteTip:
      "Das Löschen der Standard-Zahlungsmethode kann zu fehlgeschlagenen Abbuchungen für zukünftige Abonnements führen. Bitte gehen Sie daher vorsichtig vor.",
    confirmDelete:
      "Klicken Sie erneut, um die Löschung der Zahlungsmethode zu bestätigen",
  },
  groupMember: {
    title: "Gruppenmitglied",
    invite: "Einladen",
    remove: "Entfernen",
    removePrompt:
      "Sind Sie sicher, dass Sie dieses Mitglied entfernen möchten?",
    ownerPrompt:
      "Sind Sie sicher, dass Sie das Eigentum der Gruppe an dieses Mitglied {name} übertragen möchten?",
    changeOwner: "Klicken Sie erneut, um das Eigentum zu übertragen",
    setOwner: "Besitzer festlegen",
    name: "Name",
    owner: "Besitzer",
    invited: "Eingeladen",
    joined: "Zeit beitreten",
  },
  InviteMember: {
    title: "Mitglied einladen",
    searchlable: "Benutzer suchen",
    searchTip: "Unterstützung des Benutzernamens, der E-Mail oder der Benutzer-ID",
    invited: "Eingeladen",
    member: "Mitglied"
  },
  Invitation: {
    title:
      "Sie wurden eingeladen, der Gruppe ({groupName}) beizutreten, die von {name} {email} erstellt wurde. Bitte klicken Sie auf die Schaltfläche, um die Einladung anzunehmen.",
    accept: "Einladung annehmen",
    describe: "Der Beitritt zu einer Gruppe ermöglicht den Zugriff auf",
    agreed: "Einverstanden",
    expired: "abgelaufen",
  },
  yes: "Wahr",
  no: "Falsch",
  notification: {
    title: "Mitteilungen",
  },
};

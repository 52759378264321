<template>
  <!-- <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#InviteMemberModal">
    test</button> -->
  <router-view />
  <OneTimeSync />
  <TransferList />
  <PaymenttMethod />
  <notifications style="margin-top: 4rem" position="top center" />
  <Callback />
  <InviteMember />
</template>

<script>
import { onMounted, watch } from "vue";
import Callback from "./components/CallBack.vue";
import { useStore } from "vuex";
import OneTimeSync from "./components/OneTimeSync.vue";
import TransferList from "./components/TransferList.vue";
import PaymenttMethod from "./components/PaymenttMethod.vue";
import InviteMember from "./components/group/InviteMember.vue";
export default {
  name: "App",
  components: { Callback, OneTimeSync, TransferList, PaymenttMethod, InviteMember },
  setup() {
    const store = useStore()

    watch(() => store.state.theme, (val, oldVal) => {
      document.body.classList.add(val)
      document.body.classList.remove(oldVal)
    })

    onMounted(() => {
      document.body.classList.add(store.state.theme)
    })
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom.scss";

body {
  background-color: var(--body-bg) !important;
}
</style>

<template>
  <div></div>
</template>

<script>
import { inject } from "vue";
import { getSearchString } from "../utils/utils";
import jwt from "jsonwebtoken";

import { useStore } from "vuex";
import router from "../router";

export default {
  components: {},
  setup() {
    const { $msg } = inject("$");

    const store = useStore();
    let queryStr = getSearchString();

    if (queryStr.access_token) {
      callback();
    }

    function callback(val) {

      let queryStr = getSearchString();

      // access_token
      // refresh_token

      // if (!queryStr.token) return;
      let str = jwt.decode(queryStr.refresh_token);

      if (str) {
        let obj = str;
        obj.token = queryStr.access_token;
        obj.refresh_token = queryStr.refresh_token;
        localStorage.setItem("_tokenInfo", JSON.stringify(obj));
        store.commit("SET_TOKENINFO", obj);
        store.commit("SET_LOGIN_STATUS", true);
        store.commit("SET_USERNAME", str.username);

        let _wantTo = localStorage.getItem('_wantTo') //如果事先保存了路径直接跳转
        localStorage.removeItem('_wantTo')
        _wantTo ? router.replace(_wantTo) : router.replace('/console')

      } else {
        $msg({ type: "error", text: "Login failed, please try again laster" });
      }
      // //方便后台开发登录 有需要再打开
      // if (str.is_admin && location.protocol == 'http:') {
      //   window.location.replace(`http://${location.hostname}:3001/admin` + window.location.search)
      //   return
      // }

      // 删除路径上?后面
      let url = window.location.href;
      if (url.indexOf("?") != -1) {
        url = url.replace(/(\?|#)[^'"]*/, "");
        window.history.pushState({}, 0, url);
      }
    }

    return {
      callback,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>

export default {
  header: {
    myPlans: "我的計劃",
    Upgrade: "升級",
  },
  downloadModal: {
    title: "關於 Node 客戶端",
    download: "下載最新版本",
    history: "版本歷史",
    title2: "Linux、macOS:",
    bit: " 位元",
    p1: "如果您正在運行 Linux、macOS 或其他類 Unix 作業系統，且想要下載 FilePipe Node，請在您的終端機中執行以下命令，然後按照螢幕上的指示進行操作。",
    p2: "如果您正在运行 Windows 操作系统，要使用 FilePipe Node，请根据您的操作系统下载相应的客户端。",
  },
  subModal: {
    title: "訂閱資訊",
    tableTitle: "您的訂閱",
    th1: "方案",
    th2: "週期",
    th3: "付款方式",
    time1: "您已訂閱",
    time2: "下次續訂時間",
    time3: "取消時間",
    reSubscribe: "重新訂閱",
    confirm: "再次點擊確認取消",
    changeTip: "您所订阅的套餐有新的更改",
    oldPrice: "原价",
    newPrice: "新价",
    effectiveDate: "生效日期",
    updateUrlText: "請點擊此處以使用 PayPal 進行確認！",
  },
  home: {
    title1: "一個非常強大的",
    title2: "檔案傳輸系統",
    slogan: "讓多裝置檔案傳輸變得前所未有的簡單。",
    button1: "立即嘗試",
    backTop: "回到頂部",
    download: {
      title: "簡單安裝和啟動",
      1: "建立群組並獲取 API 金鑰",
      2: "在您的節點上下載並運行我們的客戶端",
      3: "在儀表板上管理傳輸",
    },
    features: {
      title1: "讓您的檔案自由流動",
      subtitle11: "您只需要設置一次，",
      subtitle12: "指定資料夾的檔案即可自動離開指定資料夾。",
      q1: "您是否在多個設備之間共享檔案的問題上遇到困難？",
      q2: "您是否遇到多個硬碟之間的檔案傳輸問題？",
      q3: "我們的程式會根據您的設置自動將檔案傳輸到您指定的路徑。",
      q4: "即使是不同的資料夾、不同的硬碟甚至是不同的作業系統。",
      title2: "完全自動的工作模式",
      subtitle21: "只需要初始化一次，",
      subtitle22: "設置完成後整個過程都會自動進行。",
      subhead1: "自動加入組別！",
      info1: "使用組別密鑰啟動您的節點，節點將自動加入您的組別，無需手動添加。",
      subhead2: "管理您的硬碟集群！",
      info2:
        "通過將硬碟附加到節點程式，方便快捷地管理大量的硬碟集群，操作明確。",
      subhead3: "文件的自動流動！",
      info3:
        "您只需要進行一次連接設置。當指定資料夾中出現檔案時，它們將自動流向目標資料夾，甚至是 LAN 下的另一個設備。",
      title3: "附帶的 Web 控制台",
      subtitle3: "視窗式的節點管理，讓節點一目了然。",
      console: {
        title1: "自由創建群組",
        info1: "所有節點都可以分組進行管理，互相之間不會互相干擾。",
        guide: "創建自己的群組",
        title2: "豐富內容，清晰結構",
        info2: "每個節點將顯示其添加的磁盤和路徑，一目瞭然。",
        title3: "清晰的狀態顯示",
        info3: "清晰地顯示每個節點下的每個路徑的工作狀態，例如",
        title4: "保持雙端同步",
        info4: "Web控制台和節點實時同步，允許隨時在Web上創建新的路徑或連接。",
        title5: "實時文件上傳列表",
        info5:
          "控制台頁面的側邊欄將顯示有關正在上傳的文件的實時信息。\n 包括文件大小，上傳速度和各種詳細信息。",
        title6: "像視窗一樣管理節點",
        info6: "隨意縮放和定位，按照您的喜好進行操作。",
        title7: "關於節點",
        info7: "節點將顯示對應機器的IP地址，也可以進行自定義以便進行管理。",
        title8: "關於磁盤",
        info8:
          "添加路徑後，它會自動找到硬盤的位置，以及實時容量顯示和高溫提示。",
        title9: "關於路徑",
        info9:
          "路徑是存儲文件的文件夾路徑，可以在您的計算機上已存在，也可以自行創建路徑，即使以前不存在。",
      },
      title4: "簡單的配置與啟動",
      subtitle4: "只需簡單設定即可啟動客戶端，一次部署，永久記住。",
      example: {
        title1: "配置文件簡單明瞭",
        title2: "完成配置即可啟動",
        info1:
          "不需要任何其他要求或操作，可以直接啟動，在後台運行不會干擾其他程序的正常運行。",
        info2: "在 Windows 系統中, 在節點所在目錄進入終端機：",
        info3: "如果你使用的是 Windows，你也可以雙擊打開。",
        linux: "在 Linux 系統中，輸入：",
        macOS: "在 macOS 系統中，輸入：",
        guide: "還沒有客戶端程式嗎？",
        download: "現在下載。",
      },
      title5: "各種好處",
      subtitle5: "FilePipe 可以讓跨設備文件傳輸變得前所未有的簡單。",
      kinds: {
        title1: "相容各種系統",
        info1:
          "包括 Windows、Mac 和 Linux，各種系統都有相應的客戶端程序，網絡控制台可以實時管理和查看您的文件傳輸。",
        title2: "附帶的網絡控制台",
        info2:
          "網絡控制台可以方便地管理所有節點和每個節點的直接文件傳輸管道的創建和管理。",
        title3: "無需註冊",
        info3:
          "直接使用 Google、GitHub 等第三方登錄，無需註冊獨立帳戶，方便快捷。",
        title4: "始終默認安全",
        info4:
          "文件傳輸始終在您的局域網中進行，不會連接到任何網絡，無需擔心文件外泄。",
      },
      ready: "準備使用 FilePipe 嗎？",
      or: "或",
      downloadText: "下載 FilePipe 客戶端程式，開始管理您的檔案。",
      downloadC: "下載客戶端",
      downloadAll: "所有歷史版本",
      subscribeT: "透過訂閱使用更多服務",
      subscribeinfo:
        "我們有多種訂閱服務，並支援多種付款方式，例如 Stripe 和 PayPal。",
      unsubscribe: "可隨時取消訂閱",
    },
    footer: {
      company: "公司",
      policy: "隱私政策",
      terms: "服務條款",
      termsTitle: "FilePipe 服務條款",
    },
  },
  pricing: {
    monthly: "月刊",
    yearly: "年刊",
    current: "現在的",
    pre: "每",
    month: "月",
    year: "年",
    reHome: "返回首頁",
    confirming: "正在確認",
    features: "特徵",
    expired: "過期",
    invitationCode: "邀請碼",
    invitePlacehold: "請輸入邀請碼",
    inviteExplain:
      "您可以輸入從其他地方獲取的邀請碼。 使用邀請碼可以讓對方受益。 如果您沒有邀請碼，可以將其留空",
    udModal: {
      title: "訂閱",
      head: "切換訂閱方案：",
      text: "切換後，您需要支付費用",
      tip: "您確定要繼續嗎？",
      udS: "訂閱切換成功",
      willToHome: "即將返回首頁",
    },
    payModal: {
      title: "支付",
      head: "購買套餐",
      needToPay: "需支付",
      text: "Stripe 偵測到您有未付款的餘額，已自動為您續訂契約。",
      paying: "支付中",
      pay: "支付",
      paySuccess: "付款成功！即將返回主頁面。",
      next: "下一步",
      saveMethodSuccess: "成功儲存付款方式。正在提交訂單",
    },
  },
  notFount: {
    title: "找不到頁面",
    info: "看起來這個頁面不見了",
    goback: "返回",
  },
  login: "登入",
  logout: "登出",
  logining: "登入中",
  loginTitle: "登入至",
  docs: "文件",
  cancel: "取消",
  save: "儲存",
  saved: "已儲存",
  sure: "確定",
  submit: "提交",
  confirm: "確認",
  subscribe: "訂閱",
  ok: "確定",
  working: "處理中",
  offline: "離線",
  error: "錯誤",
  waiting: "等待中",
  etc: "等",
  undone: "此操作無法撤消",
  copied: "已複製",
  copy: "複製代碼到剪貼板",
  empty: "空",
  console: {
    text: "控制台",
    group: "群組",
    node: "節點",
    path: "路徑",
    name: "名稱",
    download: "下載用戶端",
    createToStart: "新增群組以開始使用",
    newGroup: "新增群組",
    addNode: "新增節點",
    nodes: "節點數量",
    paths: "路徑數量",
    pipes: "管數量",
    createNewG: "新增群組",
    groupName: "群組名稱",
    createG: "新增群組",
    groupSet: "群組設定",
    general: "基本資訊",
    apikey: "API Key",
    member: "成員",
    extra: "其他設定",
    groupId: "群組 ID",
    rfapikey: "重新產生 API Key",
    deleteGroupQ: "您確定要刪除這個群組嗎？",
    dgroup: "刪除群組",
    confirmDeleteG: "再次點擊以確認刪除",
  },
  nodes: {
    IP: "IP",
    name: "名稱",
    mac: "MAC",
    mountP: "掛載點",
    backC: "返回中心",
    resetL: "重置佈局",
    fromIp: "來源 IP",
    from: "從",
    to: "到",
    nameModal: {
      title: "設置節點名稱",
      label: "新名稱",
    },
    deletePModal: {
      title: "刪除掛載路徑",
      tip: "你確定要刪除此掛載路徑嗎？",
      fromMou: "從掛載點",
      fromIp: "從IP",
      delSource: "刪除源文件（請謹慎選擇！）",
    },
    cPathModal: {
      title: "創建新路徑",
      label: "新路徑名稱",
      cPath: "創建路徑",
    },
    delNodeModal: {
      title: "刪除節點",
      tip: "你確定要刪除此節點嗎？",
      text: "刪除操作將清除此節點的相關數據，但如果您重新運行節點上的客戶端程序，則節點將重新出現在此處。",
    },
    delDiskModal: {
      title: "刪除此磁碟下的所有掛載路徑",
      tip: "確定要刪除嗎",
      strong: "此磁碟上的所有掛載路徑",
    },
    resetLModal: {
      title: "重置佈局",
      tip: "您確定要重置佈局嗎？",
    },
    mfModal: {
      title: "建立傳輸管道",
      title1: "在路徑之間建立檔案傳輸",
      title2: "同步模式",
      label1: "相對同步",
      formText1: "僅從來源傳輸檔案到目的地",
      label2: "絕對同步",
      formText2:
        "它將完全保持來源和目的地檔案的一致性，目的地多餘的檔案將會被刪除",
      title3: "強制覆蓋",
      label3: "強制覆蓋已經存在的檔案",
      title4: "驗證 md5",
      label4: "是否要驗證 md5",
      title5: "篩選器",
      title6: "刪除管道",
      formText5:
        "使用通配符模式篩選您想要傳輸的檔案，排除或包括。如果您想要傳輸所有檔案，則為“排除”設置無篩選器。",
      label5: "排除",
      label6: "包括",
      label7: "輸入通配符篩選器",
      alertDanger: "您必須設置可用的篩選器以包括檔案，否則將不會傳輸任何檔案。",
      placeHolder: "例如 *.txt",
    },
    pipeInfoModal: {
      title: "傳輸管道",
      delPipe: "刪除管道",
      details: " 詳情",
      speed: "速度",
      log: "日誌",
      autoScroll: "自動捲動",
    },
    delPipeModal: {
      title: "刪除管道",
      tip: "您確定要刪除此管道嗎？",
    },
  },
  node: {
    state: "狀態",
    version: "版本",
    newPath: "新路徑",
    setNodeName: "設定節點名稱",
    deleteNode: "刪除節點",
    tempatureTip: "硬碟溫度已達 {d}℃",
    deleteAll: "刪除此磁碟下的所有掛載路徑",
    delPath: "刪除掛載路徑",
    total: "總共",
    free: "可用",
    readOnly: "只读",
    readOnlyTip: "這個路徑只能讀取不能寫入",
  },
  wildcardTable: {
    td1: "字符",
    td2: "說明",
    td3: "範例",
    p1: "匹配字符 c (c != '\\', '-', ']')",
    p2: "匹配任何非分隔符字符序列",
    finds: "尋找",
    and: "和",
    butnot: "但不包括",
    or: "或",
    p3: "匹配任何單一非分隔符字符",
    p4: "匹配括號中的任何字符",
    p5: "匹配字符 c",
    p6: "匹配 lo <= c <= hi 的字符 c",
  },
  notify: {
    unableDown: "暫時無法下載",
    netError: "網路錯誤，請稍後再試",
    reqSuccess: "請求成功",
    upSuccess: "更新成功",
    pleaseWait: "請求已發送，請等待結果",
    invalid: "無效的操作",
    paypalNoPro: "Paypal服務無法提供，請選擇其他方法",
    createdSubSuccess: "您已成功創建訂閱",
    unableLoadPaypal: "無法載入PayPal，請稍後再試",
    groupnameExists: "該組名已存在",
    loginAgain: "請重新登入",
    loginFirst: "請先登入",
    noline: "無法連接到目標路徑，請檢查其狀態。",
  },
  tickets: "我的工單",
  ticket: {
    ticket: "Tickets",
    subTitle:
      "Create a ticket to provide feedback on issues and seek assistance",
    open: "Open",
    close: "Close",
    all: "All",
    empty: "Empty",
    cancel: "Cancel",
    submit: "Submit",
    sure: "Sure",
    created_at: "Created at",
    updated_at: "Updated at",
    undone: "This action can't be undone.",
    title: "Title",
    createModal: {
      createBtn: "Create Ticket",
      placeHolder1: "Please enter title",
      placeHolder2: "Please describe the problem you have encountered",
      formText:
        "For easier communication with the staff, we recommend using English as much as possible.",
      title: "Title",
      category: "Category",
      content: "Content",
      attachment: "Attachment",
      upload: "Upload",
    },
    putModal: {
      title: "Edit Ticket",
    },
    delModal: {
      title: "Delete Ticket",
      tip: "Are you sure to delete this ticket?",
    },
    closeModal: {
      title: "Close Ticket",
      tip: "Are you sure to close this ticket?",
    },
    commented: "Commented",
    noComment: "No comment",
    yourComment: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: "Delete comment",
  },
  createdAt: "Created at",
  updatedAt: "Update at",
  close: "Close",
  open: "Open",
  all: "All",
  title: "Title",
  category: "Category",
  content: "Content",
  comment: {
    commented: "commented",
    title: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: {
      title: "Delete comment",
      tip: "Are you sure delete this comment",
    },
  },
  balance: {
    balance: "餘額",
    order_id: "訂單編號",
    type: "類型",
    income: "收入",
    outgo: "支出",
    created_at: "創建時間",
  },
  userInfo: {
    title: "使用者資訊",
    user: "使用者",
    username: "使用者名稱",
    copied: "已複製到剪貼簿",
    invitation_code: "邀請碼",
    used_inv_code: "已使用的邀請碼",
    code_describe:
      "當其他使用者在購買訂閱時使用您的邀請碼，您將在購買訂閱時獲得折扣",
  },
  fileTree: {
    emptyFolder: "空資料夾",
    newFile: "新檔案",
    newFilePlaceholder: "請輸入檔案名稱",
    newFloder: "新資料夾",
    newFloderPlaceholder: "請輸入資料夾名稱",
    delete: "刪除",
    deletePrompt: "確定要刪除此檔案或路徑嗎",
    errorName: "檔案名稱錯誤",
    errorfloder: "資料夾名稱錯誤",
    copy: "複製",
    paste: "貼上",
    sameName: "重複檔案名稱",
    sameFolderName: "重複資料夾名稱",
  },
  oneTimeSync: {
    modalTitle: "單次同步",
    title1: "建立一次快速傳輸",
  },
  subscriptions: {
    title: "歷史訂閱",
    planname: "計劃名稱",
    amount: "金額",
    status: "狀態",
    expired: "到期",
    notExpired: "未到期",
    createtime: "開始時間",
    stopTime: "結束時間",
    PayMethod: "支付方式",
  },
  paymentMethod: {
    title: "付款方式",
    title1: "Stripe預設付款方式",
    createMethodTip: "目前尚無預設付款方式。點擊以創建一個",
    cardNumber: "卡號",
    created: "創建時間",
    expire: "到期時間",
    deleteQ: "您確定要刪除此付款方式嗎？",
    deleteTip: "刪除預設付款方式可能導致未來訂閱的扣款失敗，請謹慎操作。",
    confirmDelete: "再次點擊確認刪除付款方式",
  },
  groupMember: {
    title: "群組成員",
    invite: "邀請",
    remove: "移除",
    removePrompt: "您確定要移除此成員嗎",
    ownerPrompt: "您確定要將群組所有權轉移給這位成員{name}嗎",
    changeOwner: "點擊再次轉移所有權",
    setOwner: "設置所有者",
    name: "名稱",
    owner: "擁有者",
    invited: "已邀請",
    joined: "加入時間",
  },
  InviteMember: {
    title: "邀請成員",
    searchlable: "搜尋使用者",
    searchTip: "支持用户名、電子郵件或者使用者ID",
    invited: "已邀請",   
    member: "成員"
  },
  Invitation: {
    title:
      "您已獲邀加入{name}{email}建立的群組({groupName})。請點擊按鈕接受邀請。",
    accept: "接受邀請",
    describe: "加入群組可允許訪問",
    agreed: "已同意",
    expired: "已過期",
  },
  yes: "是",
  no: "否",
  notification: {
    title: "通知",
  },
};

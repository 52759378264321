export default {
  header: {
    myPlans: "Mes plans",
    Upgrade: "Mise à niveau",
  },
  downloadModal: {
    title: "À propos du client Node",
    download: "Télécharger FilePipe",
    history: "Version historique",
    title2: "Linux、macOS:",
    bit: "-BIT",
    p1: "Si vous utilisez Linux, macOS ou un autre système d'exploitation de type Unix et que vous souhaitez télécharger le nœud FilePipe, veuillez exécuter la commande suivante dans votre terminal et suivre les instructions à l'écran.",
    p2: "Si vous utilisez Windows, pour utiliser le nœud FilePipe, veuillez télécharger le client correspondant en fonction de votre système d'exploitation.",
  },
  subModal: {
    title: "Informations d'abonnement",
    tableTitle: "Votre abonnement",
    th1: "Plan",
    th2: "Période",
    th3: "Méthode de paiement",
    time1: "Vous avez rejoint",
    time2: "Renouvellement suivant le",
    time3: "Date d'annulation",
    reSubscribe: "Réabonnement",
    confirm: "Cliquez à nouveau pour confirmer l'annulation",
    changeTip:
      "Le forfait auquel vous êtes abonné a de nouvelles modifications",
    oldPrice: "Ancien prix",
    newPrice: "Nouveau prix",
    effectiveDate: "Date d'entrée en vigueur",
    updateUrlText: "Veuillez cliquer ici pour confirmer avec PayPal !",
  },
  home: {
    title1: "Un système de transfert de fichiers",
    title2: "incroyablement puissant",
    slogan:
      "rendant les transferts de fichiers multi-appareils incroyablement simples.",
    button1: "Essayez-le maintenant",
    backTop: "Retour en haut",
    download: {
      title: "Installation et démarrage simples",
      1: "Créez un groupe et obtenez une clé API",
      2: "Téléchargez et exécutez notre client sur vos nœuds",
      3: "Gérez les transferts sur le tableau de bord",
    },
    features: {
      title1: "Laissez vos fichiers circuler librement",
      subtitle11: "Vous n'avez besoin de configurer qu'une seule fois,",
      subtitle12:
        "et les fichiers dans le répertoire sortiront automatiquement du répertoire que vous avez spécifié.",
      q1: "Rencontrez-vous des problèmes de partage de fichiers entre plusieurs appareils?",
      q2: "Rencontrez-vous des problèmes de transfert de fichiers sur plusieurs disques?",
      q3: "Notre programme transférera automatiquement les fichiers vers le chemin que vous avez défini en fonction de vos paramètres.",
      q4: "Même dans des dossiers différents, des disques durs différents et même des systèmes d'exploitation différents.",
      title2: "Mode de fonctionnement entièrement automatique",
      subtitle21: "Il suffit de l'initialiser une fois,",
      subtitle22:
        "et l'ensemble du processus sera automatisé après la configuration.",
      subhead1: "Rejoignez automatiquement un groupe!",
      info1:
        "Utilisez la clé de groupe pour démarrer votre nœud, et le nœud rejoindra automatiquement votre groupe sans qu'il soit nécessaire de l'ajouter manuellement.",
      subhead2: "Gérez votre cluster de disques durs!",
      info2:
        "Il est pratique de gérer un grand nombre de clusters de disques durs en attachant le disque dur au programme de nœuds, ce qui est rapide, pratique et clair.",
      subhead3: "Flux de documents automatique!",
      info3:
        "Vous n'avez besoin de configurer les paramètres de connexion qu'une seule fois. Lorsque des fichiers apparaissent dans le répertoire spécifié, ils circuleront automatiquement vers le répertoire cible, même vers un autre appareil sur le réseau local.",
      title3: "La console web qui l'accompagne",
      subtitle3:
        "Gestion des nœuds basée sur la fenêtre, de sorte que les nœuds sont clairement visibles en un coup d'œil.",
      console: {
        title1: "Créez un groupe librement",
        info1:
          "Tous les nœuds sont regroupés pour une gestion facile sans interférence mutuelle.",
        guide: "Créez votre propre groupe",
        title2: "Contenu riche, structure claire",
        info2:
          "Chaque nœud affichera le disque et le chemin qu'il a ajouté, avec une structure claire en un coup d'œil.",
        title3: "Affichage clair de l'état",
        info3:
          "Affichez clairement l'état de fonctionnement de chaque chemin sous le nœud, comme",
        title4: "Gardez les deux extrémités synchronisées",
        info4:
          "La console Web et les nœuds sont synchronisés en temps réel, permettant la création de nouveaux chemins ou connexions sur le Web à tout moment.",
        title5: "Liste de téléchargement de fichiers en temps réel",
        info5:
          "La barre latérale de la page de la console affichera des informations en temps réel sur le fichier en cours de téléchargement. \n Y compris la taille du fichier, la vitesse de téléchargement et diverses informations détaillées.",
        title6: "Gérez les nœuds comme Windows",
        info6: "N'hésitez pas à zoomer et à placer selon vos préférences.",
        title7: "À propos des nœuds",
        info7:
          "Le nœud affichera l'adresse IP de la machine correspondante et peut également être personnalisé pour une gestion facile.",
        title8: "À propos des disques",
        info8:
          "Après avoir ajouté un chemin, il trouvera automatiquement l'emplacement du disque dur, ainsi qu'un affichage en temps réel de la capacité et une notification de température élevée.",
        title9: "À propos des chemins",
        info9:
          "Le chemin est le chemin de dossier où les fichiers sont stockés, qui peut déjà exister sur votre machine ou créer un chemin à volonté, même s'il n'existait pas auparavant.",
      },
      title4: "Configuration et démarrage simples",
      subtitle4:
        "Configurez simplement pour démarrer le client, déployez une fois, et rappelez-vous pour toujours.",
      example: {
        title1: "Le fichier de configuration est simple et clair",
        title2: "Complétez la configuration pour démarrer",
        info1:
          "Sans aucune autre exigence ou opération, il peut être démarré directement et fonctionne en arrière-plan sans perturber le fonctionnement normal d'autres programmes.",
        info2:
          "Une fois la configuration terminée, entrez dans le terminal dans le répertoire où se trouve le nœud :",
        info3:
          "Si vous êtes sous Windows, vous pouvez également double-cliquer pour l'ouvrir.",
        linux: "Dans le système Linux, saisissez :",
        macOS: "Dans le système macOS, saisissez :",
        guide: "Vous n'avez pas encore de programme client ? ",
        download: "Téléchargez maintenant.",
      },
      title5: "Tous types d'avantages",
      subtitle5:
        "FilePipe rend le transfert de fichiers multi-appareils incroyablement simple.",
      kinds: {
        title1: "Compatibilité avec divers systèmes",
        info1:
          "Windows, Mac et Linux sont pris en charge, chaque système ayant son propre client. Le terminal Web permet également de contrôler et de visualiser en temps réel le transfert de fichiers.",
        title2: "La console web associée",
        info2:
          "La console web permet de gérer facilement tous les nœuds et la création et la gestion de pipelines de transfert de fichiers directs pour chaque nœud.",
        title3: "Aucune inscription requise",
        info3:
          "Connectez-vous directement en utilisant un tiers tel que Google, GitHub, sans avoir besoin de créer un compte séparé, c'est pratique et rapide.",
        title4: "Sécurité par défaut, toujours",
        info4:
          "La transmission de fichiers se fait toujours dans votre LAN et ne se connecte à aucun réseau. Ne vous inquiétez pas pour les fuites de fichiers.",
      },
      ready: "Prêt à utiliser FilePipe ?",
      or: "ou",
      downloadText:
        "Téléchargez le programme client de FilePipe pour commencer votre gestion de fichiers.",
      downloadC: "Télécharger le client",
      downloadAll: "Toutes les versions historiques",
      subscribeT: "Utilisez plus de services grâce à l'abonnement",
      subscribeinfo:
        "Nous avons une variété de services d'abonnement et prenons en charge plusieurs méthodes de paiement telles que Stripe et PayPal.",
      unsubscribe: "Se désabonner à tout moment",
    },
    footer: {
      company: "Entreprise",
      policy: "Politique de confidentialité",
      terms: "Conditions d'utilisation",
      termsTitle: "Conditions d'utilisation de FilePipe",
    },
  },
  pricing: {
    monthly: "Mensuel",
    yearly: "Annuel",
    current: "Actuel",
    pre: "précédent",
    month: "mois",
    year: "an",
    reHome: "Retour à l'accueil",
    confirming: "Confirmation",
    features: "Caractéristiques",
    expired: "expiré",
    invitationCode: "Code d'invitation",
    invitePlacehold: "Veuillez entrer le code d'invitation",
    inviteExplain:
      "Vous pouvez entrer un code d'invitation obtenu ailleurs. Utiliser un code d'invitation peut bénéficier à l'autre partie. Vous pouvez le laisser vide si vous n'en avez pas",

    udModal: {
      title: "Abonnement",
      head: "Changer le forfait d'abonnement :",
      text: "Après le changement, vous devrez payer",
      tip: "Êtes-vous sûr de vouloir continuer ?",
      udS: "Changement d'abonnement réussi",
      willToHome: "Retour à la page d'accueil en cours",
    },
    payModal: {
      title: "Paiement",
      head: "Achat d'un forfait",
      needToPay: "Besoin de payer",
      text: "Stripe a détecté que vous avez conservé un solde sans paiement et a renouvelé automatiquement le contrat pour vous.",
      paying: "En cours de paiement",
      pay: "Payer",
      paySuccess:
        "Succès ! Paiement reçu. Retour à la page d'accueil en cours.",
      next: "Prochaines étapes",
      saveMethodSuccess:
        "Méthode de paiement enregistrée avec succès. Envoi de la commande en cours.",
    },
  },
  notFount: {
    title: "Page non trouvée",
    info: "Il semble que cette page ait disparu",
    goback: "Retourner en arrière",
  },
  login: "Se connecter",
  logout: "Déconnexion",
  logining: "Connexion en cours",
  loginTitle: "Se connecter à",
  docs: "Documents",
  cancel: "Annuler",
  save: "Enregistrer",
  saved: "Sauvegardé",
  sure: "Sûr",
  submit: "Soumettre",
  confirm: "Confirmer",
  subscribe: "S'abonner",
  ok: "Ok",
  working: "En cours",
  offline: "Hors ligne",
  error: "Erreur",
  waiting: "En attente",
  etc: "etc",
  undone: "Cette action ne peut pas être annulée.",
  copied: "Copié",
  copy: "Copier le code dans le presse-papiers",
  empty: "vide",
  console: {
    text: "Console",
    group: "groupe",
    node: "nœud",
    path: "chemin",
    name: "nom",
    download: "Télécharger le client",
    createToStart: "Créer un groupe pour commencer",
    newGroup: "Nouveau groupe",
    addNode: "Ajouter des nœuds",
    nodes: "Nœuds",
    paths: "Chemins",
    pipes: "Tuyaux",
    createNewG: "Créer un nouveau groupe",
    groupName: "Nom du groupe",
    createG: "Créer un groupe",
    groupSet: "Paramètres du groupe",
    general: "Général",
    apikey: "API Key",
    member: "Membre",
    extra: "Extra",
    groupId: "ID du groupe",
    rfapikey: "Actualiser la API Key",
    deleteGroupQ: "Êtes-vous sûr de vouloir supprimer ce groupe",
    dgroup: "Supprimer le groupe",
    confirmDeleteG: "Cliquez à nouveau pour confirmer la suppression",
  },
  nodes: {
    IP: "Adresse IP",
    name: "Nom",
    mac: "Adresse MAC",
    mountP: "Point de montage",
    backC: "Retour au centre",
    resetL: "Réinitialiser la disposition",
    fromIp: "Depuis l'adresse IP",
    from: "Depuis",
    to: "Vers",
    nameModal: {
      title: "Définir le nom du nœud",
      label: "Nouveau nom avec",
    },
    deletePModal: {
      title: "Supprimer le chemin de montage",
      tip: "Êtes-vous sûr de vouloir supprimer ce chemin de montage ?",
      fromMou: "À partir du point de montage",
      fromIp: "De l'adresse IP",
      delSource: "Supprimer le fichier source (veuillez choisir avec soin !)",
    },
    cPathModal: {
      title: "Créer un nouveau chemin",
      label: "Nouveau chemin avec",
      cPath: "Créer un chemin",
    },
    delNodeModal: {
      title: "Supprimer le nœud",
      tip: "Êtes-vous sûr de vouloir supprimer ce nœud ?",
      text: "L'opération de suppression effacera les données liées à ce nœud, mais si vous relancez le programme client sur le nœud, le nœud réapparaîtra ici.",
    },
    delDiskModal: {
      title: "Supprimer tous les chemins de montage sous ce disque",
      tip: "Êtes-vous sûr de vouloir supprimer",
      strong: "tous les chemins de montage sur ce disque",
    },
    resetLModal: {
      title: "Réinitialiser la disposition",
      tip: "Êtes-vous sûr de vouloir réinitialiser la disposition ?",
    },
    mfModal: {
      title: "Créer un tuyau de transfert",
      title1: "Établir le transfert de fichiers entre les chemins",
      title2: "Mode de synchronisation",
      label1: "Synchronisation relative",
      formText1:
        "Il transfère simplement les fichiers de la source à la destination",
      label2: "Synchronisation absolue",
      formText2:
        "Il conservera les fichiers de manière absolument identique entre la source et la destination, les fichiers supplémentaires à la destination seront supprimés",
      title3: "Forcer l'écrasement",
      label3: "Écraser les fichiers existants de force",
      title4: "Valider le md5",
      label4: "Voulez-vous vérifier le md5",
      title5: "Filtres",
      title6: "Supprimer le tube",
      formText5:
        "Utilisez un modèle de caractère générique pour filtrer les fichiers que vous souhaitez transférer en excluant ou en incluant. Ne définissez aucun filtre pour l'option 'Exclure' si vous souhaitez tout transférer.",
      label5: "Exclure",
      label6: "Inclure",
      label7: "Saisir des filtres génériques",
      alertDanger:
        "Vous devez définir des filtres disponibles pour l'option Inclure, sinon rien ne sera transféré.",
      placeHolder: "Par exemple : *.txt",
    },
    pipeInfoModal: {
      title: "Tuyau de transfert",
      delPipe: "Supprimer le tuyau",
      details: "Détails",
      speed: "Vitesse",
      log: "Journal",
      autoScroll: "Défilement automatique",
    },
    delPipeModal: {
      title: "Supprimer le tube",
      tip: "Êtes-vous sûr de vouloir supprimer ce tube ?",
    },
  },
  node: {
    state: "État",
    version: "Version",
    newPath: "Nouveau chemin",
    setNodeName: "Définir le nom du nœud",
    deleteNode: "Supprimer le nœud",
    tempatureTip: "Température du disque dur jusqu'à {d}℃",
    deleteAll: "Supprimez tous les chemins de montage sur ce disque.",
    delPath: "Supprimer le chemin de montage",
    total: "Total",
    free: "Libre",
    readOnly: "Lecture seule",
    readOnlyTip: "Ce chemin ne peut être que lu et non écrit",
  },
  wildcardTable: {
    td1: "Caractère",
    td2: "Description",
    td3: "Exemple",
    p1: "correspond au caractère c (c != '\\', '-', ']')",
    p2: "correspond à n'importe quelle séquence de caractères non-Séparateurs",
    finds: "trouve",
    and: "et",
    butnot: "mais pas",
    or: "ou",
    p3: "correspond à n'importe quel caractère unique non-Séparateur",
    p4: "correspond aux caractères dans les crochets.",
    p5: "correspond au caractère c",
    p6: "correspond au caractère c pour lo <= c <= hi",
  },
  notify: {
    unableDown: "Impossible de télécharger temporairement",
    netError: "Erreur de réseau, veuillez réessayer plus tard",
    reqSuccess: "Requête réussie",
    upSuccess: "Mise à jour réussie",
    pleaseWait: "Requête envoyée, veuillez attendre les résultats",
    invalid: "Opération invalide",
    paypalNoPro:
      "Le service Paypal ne peut pas être fourni, veuillez choisir une autre méthode",
    createdSubSuccess: "Vous avez créé l'abonnement avec succès",
    unableLoadPaypal:
      "Impossible de charger PayPal, veuillez réessayer plus tard",
    groupnameExists: "Le nom de groupe existe déjà",
    loginAgain: "Veuillez vous connecter à nouveau",
    loginFirst: "Veuillez vous connecter d'abord",
    noline:
      "Impossible de se connecter au chemin de destination. Veuillez vérifier son statut.",
  },
  tickets: "Billets",
  ticket: {
    ticket: "Tickets",
    empty: "Empty",
    subTitle:
      "Create a ticket to provide feedback on issues and seek assistance",
    open: "Open",
    close: "Close",
    all: "All",
    cancel: "Cancel",
    submit: "Submit",
    sure: "Sure",
    created_at: "Created at",
    updated_at: "Updated at",
    undone: "This action can't be undone.",
    title: "Title",
    createModal: {
      createBtn: "Create Ticket",
      placeHolder1: "Please enter title",
      placeHolder2: "Please describe the problem you have encountered",
      formText:
        "For easier communication with the staff, we recommend using English as much as possible.",
      title: "Title",
      category: "Category",
      content: "Content",
      attachment: "Attachment",
      upload: "Upload",
    },
    putModal: {
      title: "Edit Ticket",
    },
    delModal: {
      title: "Delete Ticket",
      tip: "Are you sure to delete this ticket?",
    },
    closeModal: {
      title: "Close Ticket",
      tip: "Are you sure to close this ticket?",
    },
    commented: "Commented",
    noComment: "No comment",
    yourComment: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: "Delete comment",
  },
  createdAt: "Created at",
  updatedAt: "Update at",
  close: "Close",
  open: "Open",
  all: "All",
  title: "Title",
  category: "Category",
  content: "Content",
  comment: {
    commented: "commented",
    title: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: {
      title: "Delete comment",
      tip: "Are you sure delete this comment",
    },
  },
  balance: {
    balance: "Solde",
    order_id: "ID de commande",
    type: "Type",
    income: "Revenu",
    outgo: "Dépense",
    created_at: "Date de création",
  },
  userInfo: {
    title: "Informations utilisateur",
    user: "Utilisateur",
    username: "Nom d'utilisateur",
    copied: "Copié dans le presse-papiers",
    invitation_code: "Code d'invitation",
    used_inv_code: "Code d'invitation utilisé",
    code_describe:
      "Lorsque d'autres utilisateurs utilisent votre code d'invitation pour acheter un abonnement, vous recevrez une réduction lors de l'achat de l'abonnement",
  },
  fileTree: {
    emptyFolder: "Dossier vide",
    newFile: "Nouveau fichier",
    newFilePlaceholder: "Veuillez entrer le nom du fichier",
    newFloder: "Nouveau dossier",
    newFloderPlaceholder: "Veuillez entrer le nom du dossier",
    delete: "Supprimer",
    deletePrompt: "Êtes-vous sûr de vouloir supprimer ce fichier ou ce dossier",
    errorName: "Nom de fichier incorrect",
    errorfloder: "Nom de dossier incorrect",
    copy: "Copier",
    paste: "Coller",
    sameName: "Nom de fichier en double",
    sameFolderName: "Nom de dossier en double",
  },
  oneTimeSync: {
    modalTitle: "Synchronisation ponctuelle",
    title1: "Établir un transfert rapide ponctuel",
  },
  subscriptions: {
    title: "Abonnements historiques",
    planname: "Nom du plan",
    amount: "Montant",
    status: "Statut",
    expired: "Expiration",
    notExpired: "Non expiré",
    createtime: "Heure de début",
    stopTime: "Heure de fin",
    PayMethod: "Méthode de paiement",
  },
  paymentMethod: {
    title: "Moyen de paiement",
    title1: "Moyen de paiement par défaut Stripe",
    createMethodTip:
      "Il n'y a actuellement aucun moyen de paiement par défaut. Cliquez pour en créer un.",
    cardNumber: "Numéro de carte",
    created: "Date de création",
    expire: "Date d'expiration",
    deleteQ: "Êtes-vous sûr de vouloir supprimer ce moyen de paiement ?",
    deleteTip:
      "La suppression du moyen de paiement par défaut peut entraîner des échecs de prélèvement pour les abonnements futurs, veuillez donc procéder avec prudence.",
    confirmDelete:
      "Cliquez à nouveau pour confirmer la suppression du moyen de paiement",
  },
  groupMember: {
    title: "Membre du groupe",
    invite: "Inviter",
    remove: "Supprimer",
    removePrompt: "Êtes-vous sûr de vouloir supprimer ce membre",
    ownerPrompt: "Êtes-vous sûr de transférer la propriété du groupe à ce membre {name}?",
    changeOwner: "Cliquez pour transférer à nouveau la propriété",
    setOwner: "Définir le propriétaire",
    name: "Nom",
    owner: "Propriétaire",
    invited: "Invité",
    joined: "Temps d'adhésion",
  },
  InviteMember: {
    title: "Inviter un membre",
    searchlable: "Rechercher des utilisateurs",
    searchTip: "Prend en charge du nom d'utilisateur, de l'e-mail ou de l'ID utilisateur",   
    invited: "Invité", 
    member: "Membres"
  },
  Invitation: {
    title:
      "Vous avez été invité à rejoindre le groupe ({groupName}) créé par {name} {email}. Veuillez cliquer sur le bouton pour accepter l'invitation.",
    accept: "Accepter l'invitation",
    describe: "Rejoindre un groupe permet d'accéder à",
    agreed: "d'accord",
    expired: "expiré",
  },
  yes: "Vrai",
  no: "Faux",
  notification: {
    title: "Notifications",
  },
};

export default {
  header: {
    myPlans: "我的计划",
    Upgrade: "升级",
  },
  downloadModal: {
    title: "关于节点客户端",
    download: "下载Windows版本",
    history: "历史版本",
    title2: "Linux、macOS:",
    bit: "位",
    p1: "如果您正在运行 Linux、macOS或其他类 Unix 的操作系统，要下载 FilePipe Node，请在您的终端中运行以下命令，然后遵循屏幕上的指示。",
    p2: "如果您正在运行Windows,要使用 FilePipe Node，请根据您的操作系统下载对应的客户端。",
  },
  subModal: {
    title: "订阅信息",
    tableTitle: "您的订阅",
    th1: "计划",
    th2: "时间段",
    th3: "支付方式",
    time1: "您已经加入了",
    time2: "下次续订时间：",
    time3: "到期时间",
    reSubscribe: "重新订阅",
    confirm: "再次点击确认取消",
    changeTip: "您订阅的套餐有新的更改",
    oldPrice: "旧价格",
    newPrice: "新价格",
    effectiveDate: "生效日期",
    updateUrlText: "请点击此处前往PayPal确认！",
  },
  home: {
    title1: "一个非常强大的",
    title2: "文件传输系统",
    slogan: "使多设备文件传输变得前所未有的简单。",
    button1: "现在就试试",
    backTop: "返回顶部",
    download: {
      title: "简单的安装和启动",
      1: "创建群组并获取API密钥",
      2: "在您的节点上下载并运行我们的客户端",
      3: "在仪表板上管理传输",
    },
    features: {
      title1: "让您的文件按需流动",
      subtitle11: "您只需要设置一次,",
      subtitle12: "然后目录中的文件将自动传输到您指定的目录。",
      q1: "您是否在多个设备之间存在文件共享问题？",
      q2: "您是否存在多磁盘文件传输问题？",
      q3: "我们的程序将根据您的设置自动将文件传输到您设置好的路径",
      q4: "即使是不同的文件夹、不同的硬盘甚至是不同的操作系统，也可以实现自动传输。",
      title2: "工作模式全自动",
      subtitle21: "它只需要进行一次初始化，",
      subtitle22: "设置完成后，整个过程将会自动化。",
      subhead1: "自动加入群组！",
      info1: "使用群组密钥启动您的节点，节点将自动加入您的群组，无需手动添加。",
      subhead2: "管理您的硬盘集群！",
      info2:
        "通过将硬盘连接到节点程序，方便快捷地管理大量硬盘集群，使其清晰易懂。",
      subhead3: "文件自动流动！",
      info3:
        "您只需要进行一次连接设置。当文件出现在指定目录中时，它们将自动流向目标目录，甚至可以流向局域网下的另一台设备。",
      title3: "配套的Web控制台",
      subtitle3: "窗口式的节点管理,所有节点一目了然。",
      console: {
        title1: "自由创建群组",
        info1: "所有节点都有自己的分组，互不干扰，方便管理。",
        guide: "创建您自己的群组",
        title2: "内容丰富，结构清晰",
        info2: "每个节点都会显示其已添加的硬盘和路径，一目了然，结构清晰。",
        title3: "清晰的状态显示",
        info3: "清晰地显示节点下每个路径的工作状态，例如：",
        title4: "保持两端同步",
        info4: "Web控制台和节点实时同步，允许在Web上随时创建新路径或连接。",
        title5: "实时文件传输列表",
        info5:
          "控制台页面的侧边栏将显示有关正在上传的文件的实时信息，包括文件大小、上传速度和各种详细信息。",
        title6: "像窗口一样管理节点",
        info6: "随意缩放，并根据您的喜好放置节点。",
        title7: "关于节点",
        info7:
          "节点将显示相应计算机的IP地址等信息，也可以进行自定义以便于管理。",
        title8: "关于硬盘",
        info8:
          "添加路径后，它将自动查找硬盘的位置，以及实时容量显示和高温提示。",
        title9: "关于路径",
        info9:
          "路径是存储文件的文件夹路径，可以在您的计算机上已经存在，也可以随意创建一个路径，即使之前不存在。",
      },
      title4: "简单的配置和启动",
      subtitle4: "只需简单的配置即可启动客户端，部署一次，永久记住。",
      example: {
        title1: "配置文件简单清晰",
        title2: "完成配置即可启动",
        info1:
          "不需要任何其他要求或操作，只需直接启动即可，并且在后台运行时不会干扰其他程序的正常操作。",
        info2: "Windows系统下，在节点所在的目录中终端输入：",
        info3: "如果您使用的是Windows，也可以双击打开它。",
        linux: "Linux 系统下启动命令为：",
        macOS: "macOs 系统下启动命令为：",

        guide: "还没有客户端程序？",
        download: "立即下载",
      },
      title5: "各种各样的好处",
      subtitle5: "FilePipe可以让多设备文件传输变得前所未有的简单。",
      kinds: {
        title1: "兼容各种系统",
        info1:
          "包括Windows、Mac和Linux，各种系统都有对应的客户端程序，而网页终端可以实时控制和查看您的文件传输。",
        title2: "可以搭配的 Web 控制台",
        info2:
          "Web控制台可以方便地管理所有节点，并对每个节点创建和管理直接文件传输管道。",
        title3: "无需另外注册账号",
        info3:
          "可以直接使用第三方登录，例如{d}等，无需注册独立帐户，方便快捷。",
        title4: "始终确保文件的安全性",
        info4:
          "文件传输始终在您的局域网内进行，并不会连接到任何网络。不必担心文件泄露。",
      },
      ready: "准备好使用FilePipe了?",
      or: "或者",
      downloadText: "下载FilePipe客户端程序，开始管理您的文件。",
      downloadC: "下载客户端程序",
      downloadAll: "所有历史版本",
      subscribeT: "通过订阅套餐使用更多的服务",
      subscribeinfo:
        "我们提供多种订阅服务，支持多种付款方式，如 Stripe 和 PayPal。",
      subscribeinfo_cn: "我们提供多种订阅服务，支持使用 支付宝 付款。",
      unsubscribe: "可随时退订",
    },
    footer: {
      company: "关于我们",
      policy: "隐私政策",
      terms: "服务条款",
      termsTitle: "FilePipe 服务条款",
    },
  },
  pricing: {
    monthly: "按月",
    yearly: "按年",
    current: "当前",
    pre: "每",
    month: "月",
    year: "年",
    reHome: "返回首页",
    confirming: "正在确认",
    features: "特点",
    expired: "已过期",
    invitationCode: "邀请码",
    invitePlacehold: "请输入邀请码",
    inviteExplain:
      "可以输入从他处获得的邀请码，使用邀请码可以给对方带来收益。如果没有可以不填",

    udModal: {
      title: "订阅",
      head: "切换订阅套餐:",
      text: "切换之后的套餐费用是:",
      tip: "您确定要继续吗？",
      udS: "更新订阅成功",
      willToHome: "即将返回控制台",
    },
    payModal: {
      title: "支付",
      head: "购买套餐",
      needToPay: "需要支付",
      text: "Stripe检测到您未支付的余额，已自动为您续订合同。",
      paying: "正在支付",
      pay: "支付",
      paySuccess: "成功！已收到付款。即将返回控制台。",
      next: "下一步",
      saveMethodSuccess: "支付方式保存成功 正在提交订单...",
    },
  },
  notFount: {
    title: "没有找到",
    info: " 这个页面好像已经丢失了。",
    goback: "返回",
  },
  login: "登录",
  logout: "退出登录",
  logining: "正在登录",
  loginTitle: "登录到",
  docs: "文档",
  cancel: "取消",
  save: "保存",
  saved: "已保存",
  sure: "确认",
  confirm: "确认",
  submit: "提交",
  subscribe: "订阅",
  ok: "好的",
  working: "工作中",
  offline: "离线",
  error: "错误",
  waiting: "加载中",
  etc: "等等",
  undone: "此操作无法撤销",
  copied: "已复制",
  copy: "复制到粘贴板",
  empty: "暂无数据",
  console: {
    text: "控制台",
    group: "群组",
    node: "节点",
    path: "路径",
    name: "名称",
    download: "下载客户端",
    createToStart: "创建一个群组以开始",
    newGroup: "新建群组",
    addNode: "添加节点",
    nodes: "节点",
    paths: "路径",
    pipes: "管道",
    createNewG: "创建群组",
    groupName: "群组名称",
    createG: "创建群组",
    groupSet: "群组设置",
    general: "通用",
    apikey: "API Key",
    member: "成员",
    extra: "其他",
    groupId: "群组 id",
    rfapikey: "重置 API Key",
    deleteGroupQ: "你确定要删除这个群组吗",
    dgroup: "删除群组",
    confirmDeleteG: "再次点击确认删除",
  },
  nodes: {
    IP: "IP",
    name: "名称",
    mac: "Mac地址",
    mountP: "挂载点",
    backC: "回到中心",
    resetL: "重新排列",
    fromIp: "起始ip",
    from: "从",
    to: "到",
    nameModal: {
      title: "设置节点名称",
      label: "新的名称",
    },
    deletePModal: {
      title: "删除挂载路径",
      tip: "你确定要删除这个挂载路径吗?",
      fromMou: "所属挂载点",
      fromIp: "所属ip",
      delSource: "是否删除源文件 (请谨慎选择!)",
    },
    cPathModal: {
      title: "创建新的路径",
      label: "新路径加入",
      cPath: "创建路径",
    },
    delNodeModal: {
      title: "删除节点",
      tip: "你确定要删除这个节点吗？",
      text: "这个删除操作将清除此节点的相关数据，但是如果您在该节点上重新运行客户端程序，则该节点将重新出现在这里。",
    },
    delDiskModal: {
      title: "删除硬盘下所有挂载路径",
      tip: "你确定要删除",
      strong: "这个硬盘下所有挂载路径吗？",
    },
    resetLModal: {
      title: "重新排列",
      tip: "你确定要重新排列吗?",
    },
    mfModal: {
      title: "创建传输管道",
      title1: "在路径之间建立文件传输",
      title2: "同步模式",
      label1: "相对同步",
      formText1: "它只是将文件从源传输到目标地。",
      label2: "绝对同步",
      formText2:
        "它将确保源和目标之间的文件完全相同，目标上多余的文件将被删除。",
      title3: "强制覆盖",
      label3: "强制覆盖已经存在的文件",
      title4: "验证md5",
      label4: "您想要验证md5吗？",
      title5: "筛选器",
      title6: "删除管道",
      formText5:
        "使用通配符模式来过滤你想要传输的文件，包括或排除某些文件。如果你想要传输所有文件，则不要设置“排除”过滤器。",
      label5: "排除",
      label6: "包含",
      label7: "输入通配符过滤器",
      alertDanger: "您必须将可用的筛选器设置为“包含”，否则将不会传输任何文件。",
      placeHolder: "例如 *.txt",
    },
    pipeInfoModal: {
      title: "传输管道",
      delPipe: "删除管道",
      details: " 详情",
      speed: "速度",
      log: "日志",
      autoScroll: "自动滚动",
    },
    delPipeModal: {
      title: "删除管道",
      tip: "你确定要删除这条管道吗?",
    },
  },
  node: {
    state: "状态",
    version: "版本",
    newPath: "新路径",
    setNodeName: "设置节点名称",
    deleteNode: "删除节点",
    tempatureTip: "硬盘温度高达{d}℃",
    deleteAll: "删除硬盘下所有挂载路径",
    delPath: "删除挂载路径",
    total: "总共",
    free: "可用",
    readOnly: "只读",
    readOnlyTip: "此路径只能读取，不能写入",
  },
  wildcardTable: {
    td1: "字符",
    td2: "简介",
    td3: "例子",
    p1: "匹配字符c（c != '', '-', ']'）",
    p2: "匹配任意数量的非分隔符字符序列",
    finds: "匹配",
    and: "和",
    butnot: "但是不匹配",
    or: "或",
    p3: "该表达式匹配任意单个非分隔符字符。",
    p4: "匹配括号中的字符。",
    p5: "匹配字符 c",
    p6: "匹配满足 lo <= c <= hi 条件的字符 c。",
  },
  notify: {
    unableDown: "暂时无法下载",
    netError: "网络错误，请稍后再试",
    reqSuccess: "请求成功",
    upSuccess: "更新成功",
    pleaseWait: "请求已发送，请等待结果",
    invalid: "无效的操作",
    paypalNoPro: "无法提供PayPal服务，请选择其他方式",
    createdSubSuccess: "您已成功创建订阅",
    unableLoadPaypal: "无法加载PayPal，请稍后再试",
    groupnameExists: "该群组名称已存在",
    loginAgain: "请重新登录",
    loginFirst: "请先登录",
    noline: "无法连接到目标路径，请检查其状态。",
  },
  tickets: "我的工单",
  ticket: {
    ticket: "工单",
    subTitle: "创建工单可以反馈问题并寻求帮助",
    open: "开启",
    close: "关闭",
    all: "全部",
    empty: "空",
    cancel: "取消",
    submit: "提交",
    sure: "确定",
    save: "保存",
    created_at: "创建时间",
    updated_at: "更新时间",
    undone: "此操作无法撤消.",
    title: "标题",
    createModal: {
      createBtn: "创建工单",
      placeHolder1: "请输入标题",
      placeHolder2: "请描述您遇到的问题",
      formText: "为了更容易与我们沟通，我们建议尽可能使用英语。",
      title: "标题",
      category: "分类",
      content: "内容",
      attachment: "附件",
      upload: "上传",
    },
    putModal: {
      title: "编辑工单",
    },
    delModal: {
      title: "删除工单",
      tip: "你确定要删除这个工单吗?",
    },
    closeModal: {
      title: "关闭工单",
      tip: "你确定要关闭这个工单吗?",
    },
    commented: "回复时间",
    noComment: "没有回复",
    yourComment: "您的回复",
    placeHolder: "请输入你的回复",
    comment: "回复",
    delComment: "删除回复",
  },
  comment: {
    commented: "回复时间",
    title: "您的回复",
    placeHolder: "请输入你的回复",
    comment: "回复",
    delComment: {
      title: "Delete comment",
      tip: "你确定要删除这条回复吗",
    },
  },
  balance: {
    balance: "我的余额",
    order_id: "订单号",
    type: "类型",
    income: "收入",
    outgo: "支出",
    created_at: "创建时间",
  },
  userInfo: {
    title: "用户信息",
    user: "用户",
    username: "用户名",
    copied: "已复制到剪贴板",
    invitation_code: "邀请码",
    used_inv_code: "已使用的邀请码",
    code_describe:
      "其他用户在购买订阅时使用您的邀请码，您将在购买订阅时获得折扣",
  },
  fileTree: {
    emptyFolder: "空文件夹",
    newFile: "新建文件",
    newFilePlaceholder: "请输入文件名",
    newFloder: "新建文件夹",
    newFloderPlaceholder: "请输入文件夹名",
    delete: "删除",
    deletePrompt: "你确定要删除这个文件或路径吗",
    errorName: "错误的文件名",
    errorfloder: "错误的文件夹名",
    copy: "复制",
    paste: "粘贴",
    sameName: "重复文件名",
    sameFolderName: "重复文件夹名",
  },
  oneTimeSync: {
    modalTitle: "一次性同步",
    title1: "建立一次性快速传输",
  },
  subscriptions: {
    title: "历史订阅",
    planname: "计划名称",
    amount: "金额",
    status: "状态",
    expired: "到期",
    notExpired: "未到期",
    createtime: "开始时间",
    stopTime: "结束时间",
    PayMethod: "支付方式",
  },
  paymentMethod: {
    title: "支付方式",
    title1: "Stripe 默认支付方式",
    createMethodTip: "当前没有默认支付方式。单击以创建一种",
    cardNumber: "卡号",
    created: "创建时间",
    expire: "到期时间",
    deleteQ: "您确定要删除此支付方式吗",
    deleteTip: "删除默认支付方式可能会导致未来订阅的扣款失败，请谨慎操作。",
    confirmDelete: "再次单击以确认删除支付方式",
  },

  groupMember: {
    title: "群成员",
    invite: "邀请",
    remove: "移除",
    removePrompt: "确定要移除该成员吗",
    ownerPrompt: "确定要将群所有权转让给这位成员{name}吗",
    changeOwner: "点击再次转让所有权",
    setOwner: "设为所有者",
    name: "姓名",
    owner: "所有者",
    invited: "已邀请",
    joined: "加入时间",
  },
  InviteMember: {
    title: "邀请成员",
    searchlable: "搜索用户",
    searchTip: "支持用户名、电子邮件或者用户ID",
    invited: "已邀请",
    member: "成员"
  },
  Invitation: {
    title:
      "您已被邀请加入{name}{email}创建的群组({groupName})。请点击按钮接受邀请。",
    accept: "接受邀请",
    describe: "加入群组可以访问以下内容",
    agreed: "已同意",
    expired: "已失效",
  },
  yes: "是",
  no: "否",
  notification: {
    title: "通知",
  },
};

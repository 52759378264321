<template>
  <div class="modal fade" id="InviteMemberModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ t(pre + 'title') }}</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"
            ref="closeLogin" @click="handleCancle" data-bs-toggle="modal" data-bs-target="#GroupSettingsModal"></button>
        </div>
        <div class="modal-body" style="height: 500px;">
          <label for="exampleFormControlInput1" class="form-label">{{ t(pre + 'searchlable') }}
            <span v-if="!$store.state.production_cn">({{ t(pre + 'searchTip') }})</span>
            <span v-if="$store.state.production_cn">(支持用户名和用户ID)</span>
          </label>
          <div class="input-group mb-3">
            <input type="email" class="form-control" v-model.trim="serchText" @keydown.enter="handleSearch">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="handleSearch"
              :disabled="searching || !serchText">
              <i class="bi bi-search"></i>
            </button>
          </div>
          <div class="my-2 h-75 overflow-auto">
            <Loading class="text-center" v-if="searching" />
            <Empty class="position-absolute top-50 start-50 translate-middle" v-if="!searching && userList.length == 0" />

            <div class="d-flex align-items-center justify-content-between p-2 user-item" v-for="(user, index) in userList"
              :key="index">
              <div class="d-flex align-items-center w-75 ">
                <div class="mx-2">
                  <template v-if="user.picture || user.avatar">
                    <img :src="userInfo.avatar || tokenInfo.picture" v-if="user.avatar" class="rounded-circle  avatar" />
                    <img :src="user.picture" v-if="user.picture" class="rounded-circle avatar " />
                  </template>
                  <i class="bi bi-file-person-fill" v-else></i>
                </div>
                <div class="text-truncate" style="width: 85%;">
                  {{ user.username }}
                  {{ user.email }}
                </div>
              </div>
              <button class="btn btn-sm btn-primary" v-if="user.status == 'invited'" disabled>
                {{ t(pre + 'invited') }}
              </button>
              <button class="btn btn-sm btn-primary" v-if="user.status == 'member'" disabled>
                {{ t(pre + 'member') }}
              </button>
              <button class="btn btn-sm btn-primary" @click="handleInvite(index)" v-else-if="user.status == 'can_invite'">
                <Loading v-if="submiting" sm />
                {{ t('groupMember.invite') }}
              </button>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn border" data-bs-dismiss="modal" :disabled="loading" data-bs-toggle="modal"
            data-bs-target="#GroupSettingsModal" @click="handleCancle">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, inject, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
export default {
  name: '',
  props: [],
  setup() {
    const v = reactive({
      serchText: '',
      loading: false,
      userList: [],
      searching: false,
      submiting: false,
    })
    const { t } = useI18n()
    const store = useStore()
    const { $axios, $notify } = inject("$")

    const groupInfo = computed(() => {
      return store.state.groupInfo
    })

    async function searchUser() {
      try {
        let { serchText } = v
        if (!serchText) return
        let group = groupInfo.value.id
        if (!searchUser) return
        v.searching = true
        const res = await $axios.get(`/api/v1/users?search=${serchText}&group=${group}`)
        if (res.data) {
          v.userList = res.data
        }
      } catch (error) {
        console.error(error);
      } finally {
        v.searching = false
      }
    }

    const handleSearch = throttle(searchUser, 600);

    function throttle(func, limit) {
      let inThrottle;
      return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => inThrottle = false, limit);
        }
      };
    }

    function handleCancle(params) {
      v.serchText = ''
      v.userList = []
    }

    async function handleInvite(index) {
      try {
        let item = v.userList[index]
        v.submiting = true
        const res = await $axios.post('/api/v1/groupInvitation', {
          group: groupInfo.value.id,
          user_id: item.id,
        })

        if (res.status == 200) {
          v.userList[index].status = 'invited'
        }
      } catch (error) {
        console.error(error);
      } finally {
        v.submiting = false
      }
    }

    return {
      ...toRefs(v), t, pre: "InviteMember.", handleSearch, handleCancle, handleInvite, groupInfo,
    }
  }
}
</script>
<style lang="scss" scoped>
.user-item {
  &:hover {
    cursor: pointer;
    background-color:var(--hover-bg);
  }

}


.avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid transparent
}
</style>
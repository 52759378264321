import axios from "axios";
import router from "../router";
import { notify } from "@kyvg/vue3-notification";
// import config from "../../config";
import store from "../store";
import { saveTokenInfo } from "./utils";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const instance = axios.create({
  cancelToken: source.token,
});

const config = getConfig();

instance.defaults.baseURL = `${config.master}`;
axios.defaults.baseURL = `${config.master}`;

// post请求头
// axios.defaults.headers.post["Content-Type"] = "application/json";

// 请求拦截
instance.interceptors.request.use(
  async (config) => {
    // const now = new Date().getTime();
    const CancelToken = axios.CancelToken;
    const tokenInfo = JSON.parse(localStorage.getItem("_tokenInfo"));
    if (!tokenInfo) {
      return {
        ...config,
        cancelToken: new CancelToken((cancel) =>
          cancel("Token invalid or expired,Please login again")
        ),
      };
    }
    config.headers.Authorization = `Bearer ${tokenInfo.token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let msg = null;
// 响应拦截
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    }
  },
  async (error) => {
    if (error.response)
      switch (error.response.status) {
        case 500: {
          if (msg != error.response.data) {
            notify({
              type: "error",
              title: "Network error",
              text: error.response.data,
            });
            msg = error.response.data;
            setTimeout(() => (msg = null), 500);
          }
          break;
        }
        case 400: {
          notify({
            type: "error",
            title: "Request Fail",
            text: error.response.data,
          });
          break;
        }
        case 401:
          {
            if (error.config.url.includes("/api/v1/refreshToken")) {
              logout();
              return Promise.reject(error);
            } else if (!error.config._retry) {
              error.config._retry = true;
              await refresh_token();
              return instance(error.config);
            } else {
              notify({ type: "error", text: error.response.data });
              logout();
            }
          }
          break;
        case 500: {
          if (error.config.url.includes("/api/v1/refreshToken")) {
            logout();
            notify({
              type: "error",
              text: "Token invalid or expired,Please login again",
            });
            return Promise.reject(error);
          }
        }
      }
    return Promise.reject(error);
  }
);
export default {
  get(url, data) {
    return new Promise(async (resolve, reject) => {
      instance({ method: "get", url, params: data })
        .then(resolve)
        .catch(reject);
    });
  },
  openget(url, data) {
    return new Promise((resolve, reject) => {
      axios({ method: "get", url, params: data })
        .then(resolve)
        .catch(reject);
    });
  },
  post(url, data) {
    return new Promise((resolve, reject) => {
      instance({ method: "post", url, data })
        .then(resolve)
        .catch(reject);
    });
  },
  openpost(url, data) {
    return new Promise((resolve, reject) => {
      axios({ method: "post", url, data })
        .then(resolve)
        .catch(reject);
    });
  },
  delete(url, data) {
    return new Promise((resolve, reject) => {
      instance({ method: "delete", url, data })
        .then(resolve)
        .catch(reject);
    });
  },
  put(url, data) {
    return new Promise((resolve, reject) => {
      instance({ method: "put", url, data })
        .then(resolve)
        .catch(reject);
    });
  },
  upload(url, data) {
    return new Promise((resolve, reject) => {
      instance
        .post(url, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(resolve)
        .catch(reject);
    });
  },
  refresh_token,
};

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

let refreshing = false;

async function refresh_token() {
  const tokenInfo = JSON.parse(localStorage.getItem("_tokenInfo"));
  if (!tokenInfo) {
    logout();
    return;
  }
  let refresh_token = tokenInfo.refresh_token;

  if (!refresh_token) {
    logout();
  }
  if (refreshing) {
    await sleep(2500);
    return;
  }
  refreshing = true;
  try {
    let resp = await instance({
      url: `${config.master}/api/v1/refreshToken`,
      method: "post",
      data: { refresh_token },
    });

    saveTokenInfo(resp.data);
  } catch (error) {
    console.log(error);
    logout();
  } finally {
    refreshing = false;
  }
}

function logout(params) {
  store.commit("SET_LOGIN_STATUS", false);
  localStorage.clear();
  router.replace("/");
}

<template>
  <div>
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#TransferListModal"
      id="TransferList" hidden>
    </button>

    <!-- Modal -->
    <div class="modal fade" id="TransferListModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">文件传输进度</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="" v-for="item in 3">
              <div class="progress">
                <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 10%" aria-valuenow="10"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref="closeModal"
              @click="handleCancle">Close</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, inject, computed, } from 'vue'
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: '',
  props: [],
  setup() {
    const { $axios, $notify } = inject('$')
    const v = reactive({
      filters: [],
      newFilter: null,
      oneTimeSyncInfo: {},
      closeModal: null,
    })
    const store = useStore()
    const { t } = useI18n()



    const TransferList = computed(() => {
      return store.state.transferList
    })

    async function subimt() {
      try {
        let obj = Object.assign(v.oneTimeSyncInfo, {
          validate_md5: false
        });
        const res = await $axios.post('/api/v1/oneTimeSync', obj)
        $notify({ type: "info", text: t('notify.pleaseWait') });
        v.closeModal.click();
      } catch (error) {
        console.error(error);
      }
    }

    function handleCancle(params) {
      store.commit("SET_ONETIMESYNC", null)
      store.commit("SET_TARGETPOSITION", {})
    }

    return {
      ...toRefs(v), t, handleCancle, TransferList,
    }
  }
}
</script>
<style scoped></style>

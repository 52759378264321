export default {
  header: {
    myPlans: "Mis planes",
    Upgrade: "Mejorar",
  },
  downloadModal: {
    title: "Acerca del cliente Node",
    download: "Descargar FilePipe.",
    history: "Versiones anteriores",
    title2: "Linux、macOS:",
    bit: "-BITS",
    p1: "Si está utilizando Linux, macOS u otro sistema operativo similar a Unix y desea descargar el FilePipe Node, ejecute el siguiente comando en su terminal y siga las instrucciones en pantalla.",
    p2: "Si está utilizando Windows, para usar el FilePipe Node, descargue el cliente correspondiente según su sistema operativo.",
  },
  subModal: {
    title: "Información de suscripción",
    tableTitle: "Tu suscripción",
    th1: "Plan",
    th2: "Periodo",
    th3: "Método de pago",
    time1: "Te has unido",
    time2: "Próxima renovación en",
    time3: "Tiempo de cancelación",
    reSubscribe: "Volver a suscribirse",
    confirm: "Haz clic de nuevo para confirmar la cancelación",
    changeTip: "El paquete al que te suscribiste tiene nuevos cambios",
    oldPrice: "Precio anterior",
    newPrice: "Nuevo precio",
    effectiveDate: "Fecha de inicio",
    updateUrlText: "¡Haz clic aquí para confirmar con PayPal!",
  },
  home: {
    title1: "Increíblemente potente",
    title2: "Un sistema de transferencia de archivos ",
    slogan:
      "haciendo que las transferencias de archivos multi-dispositivo sean increíblemente simples.",
    button1: "Pruébalo ahora",
    backTop: "Volver arriba",
    download: {
      title: "Instalación y inicio simples",
      1: "Crea un grupo y obtén una clave API",
      2: "Descarga y ejecuta nuestro cliente en tus nodos",
      3: "Administra las transferencias en el panel de control",
    },
    features: {
      title1: "Deje que sus archivos fluyan según sea necesario",
      subtitle11: "Solo necesita configurar una vez,",
      subtitle12:
        "y los archivos en el directorio saldrán automáticamente del directorio que especificó.",
      q1: "¿Tiene problemas para compartir archivos entre varios dispositivos?",
      q2: "¿Tiene problemas de transferencia de archivos múltiples de disco?",
      q3: "Nuestro programa transferirá automáticamente los archivos a la ruta que configuró según sus ajustes.",
      q4: "Incluso diferentes carpetas, diferentes discos duros e incluso diferentes sistemas operativos.",
      title2: "Modo de trabajo totalmente automático",
      subtitle21: "Solo necesita inicializarse una vez,",
      subtitle22:
        "y todo el proceso se automatizará después de la configuración.",
      subhead1: "¡Únete automáticamente a un grupo!",
      info1:
        "Use la clave del grupo para iniciar su nodo, y el nodo se unirá automáticamente a su grupo sin agregarlo manualmente.",
      subhead2: "¡Administre su clúster de disco duro!",
      info2:
        "Es conveniente administrar una gran cantidad de clústeres de discos duros conectando el disco duro al programa del nodo, que es rápido, conveniente y claro.",
      subhead3: "¡Flujo automático de documentos!",
      info3:
        "Solo necesita realizar la configuración de conexión una vez. Cuando aparezcan archivos en el directorio especificado, fluirán automáticamente al directorio de destino, incluso a otro dispositivo bajo la LAN.",
      title3: "La consola web acompañante",
      subtitle3:
        "Gestión de nodos basada en ventanas, para que los nodos sean claros de un vistazo.",
      console: {
        title1: "Crear grupo libremente",
        info1:
          "Todos los nodos se agrupan más fácilmente para una gestión sencilla sin interferir entre sí.",
        guide: "Crea tu propio grupo",
        title2: "Contenido rico, estructura clara",
        info2:
          "Cada nodo mostrará el disco y la ruta que ha agregado, con una estructura clara de un vistazo.",
        title3: "Pantalla de estado clara",
        info3:
          "Muestra claramente el estado de trabajo de cada ruta en el nodo, como",
        title4: "Mantener sincronizados ambos extremos",
        info4:
          "La consola web y los nodos se sincronizan en tiempo real, lo que permite la creación de nuevas rutas o conexiones en la web en cualquier momento.",
        title5: "Lista de carga de archivos en tiempo real",
        info5:
          "La barra lateral de la página de la consola mostrará información en tiempo real sobre el archivo que se está cargando. \nIncluyendo tamaño de archivo, velocidad de carga y diversos detalles.",
        title6: "Administre los nodos como Windows",
        info6:
          "Siéntase libre de hacer zoom y colocarlo según sus preferencias.",
        title7: "Sobre nodos",
        info7:
          "El nodo mostrará la dirección IP de la máquina correspondiente y también se puede personalizar para facilitar la gestión.",
        title8: "Acerca de los discos",
        info8:
          "Después de agregar una ruta, encontrará automáticamente la ubicación del disco duro, así como la visualización de la capacidad en tiempo real y la advertencia de alta temperatura.",
        title9: "Sobre las rutas",
        info9:
          "La ruta es la ruta de la carpeta donde se almacenan los archivos, que ya pueden existir en su máquina o crear una ruta a voluntad, incluso si no existía antes.",
      },
      title4: "Configuración y inicio sencillos",
      subtitle4:
        "Simplemente configure para iniciar el cliente, implemente una vez, recuerde para siempre.",
      example: {
        title1: "El archivo de configuración es simple y claro",
        title2: "Complete la configuración para comenzar",
        info1:
          "Sin ningún otro requisito u operación, se puede iniciar directamente, y la ejecución en segundo plano no interferirá con el funcionamiento normal de otros programas.",
        info2:
          "Después de completar la configuración, ingrese al terminal en el directorio donde se encuentra el nodo:",
        info3:
          "Si estás en Windows, también puedes hacer doble clic para abrirlo.",
        linux: "En el sistema Linux, ingresa:",
        macOS: "En el sistema macOS, ingresa:",
        guide: "¿Aún no tiene un programa de cliente?",
        download: "Descargar ahora.",
      },
      title5: "Todo tipo de beneficios",
      subtitle5:
        "FilePipe hace que la transferencia de archivos entre múltiples dispositivos sea increíblemente simple.",
      kinds: {
        title1: "Compatible con varios sistemas",
        info1:
          "Incluyendo Windows, Mac y Linux, varios sistemas tienen programas de cliente correspondientes, y la terminal web puede controlar y ver su transferencia de archivos en tiempo real.",
        title2: "La consola web acompañante",
        info2:
          "La consola web puede manejar convenientemente todos los nodos y la creación y gestión de tuberías de transferencia de archivos directas para cada nodo.",
        title3: "No se requiere registro",
        info3:
          "Inicie sesión directamente utilizando un tercero como Google, GitHub, sin necesidad de registrarse una cuenta separada, conveniente y rápido.",
        title4: "Seguridad predeterminada, siempre",
        info4:
          "La transmisión de archivos siempre está en su LAN y no se conectará a ninguna red. No se preocupe por la filtración de archivos.",
      },
      ready: "¿Listo para usar FilePipe?",
      or: "o",
      downloadText:
        "Descargue el programa cliente de FilePipe para comenzar la gestión de sus archivos.",
      downloadC: "Descargar cliente",
      downloadAll: "Todas las versiones históricas",
      subscribeT: "Utilice más servicios a través de la suscripción",
      subscribeinfo:
        "Tenemos una variedad de servicios por suscripción y admitimos múltiples métodos de pago, como Stripe y PayPal.",
      unsubscribe: "Darse de baja en cualquier momento",
    },
    footer: {
      company: "Empresa",
      policy: "Política de Privacidad",
      terms: "Términos de Servicio",
      termsTitle: "Términos de Servicio de FilePipe",
    },
  },
  pricing: {
    monthly: "Mensual",
    yearly: "Anual",
    current: "Actual",
    pre: "Anterior",
    month: "mes",
    year: "año",
    reHome: "Volver a la página principal",
    confirming: "Confirmando",
    features: "Características",
    expired: "caducado",
    invitationCode: "Código de invitación",
    invitePlacehold: "Por favor, introduce el código de invitación",
    inviteExplain:
      "Puede ingresar un código de invitación obtenido de otra fuente. El uso de un código de invitación puede beneficiar a la otra parte. Puede dejarlo en blanco si no tiene uno",

    udModal: {
      title: "Suscripción",
      head: "Cambiar paquete de suscripción:",
      text: "Después de cambiar, debes pagar",
      tip: "¿Estás seguro de que deseas continuar?",
      udS: "Cambio de suscripción exitoso",
      willToHome: "A punto de regresar a la página principal",
    },
    payModal: {
      title: "Pago",
      head: "Comprando paquete",
      needToPay: "Necesita pagar",
      text: "Stripe ha detectado que tiene un saldo pendiente sin pago y ha renovado automáticamente el contrato por usted.",
      paying: "Pagando",
      pay: "Pagar",
      paySuccess:
        "¡Pago exitoso! Pago recibido. A punto de regresar a la página de inicio.",
      next: "Siguiente paso",
      saveMethodSuccess:
        "El método de pago se ha guardado con éxito. Enviando orden.",
    },
  },
  notFount: {
    title: "No encontrado",
    info: "Parece que esta página ha desaparecido",
    goback: "Volver",
  },
  login: "Iniciar sesión",
  logout: "Cerrar sesión",
  logining: "Iniciando sesión",
  loginTitle: "Iniciar sesión en",
  docs: "Documentos",
  cancel: "Cancelar",
  save: "Guardar",
  saved: "Guardado",
  sure: "Seguro",
  submit: "Enviar",
  confirm: "Confirmar",
  subscribe: "Suscribirse",
  ok: "Ok",
  working: "Trabajando",
  offline: "Sin conexión",
  error: "Error",
  waiting: "Esperando",
  etc: "etc",
  undone: "Esta acción no se puede deshacer.",
  copied: "Copiado",
  copy: "Copiar código al portapapeles",
  empty: "vacío",
  console: {
    text: "Consola",
    group: "grupo",
    node: "nodo",
    path: "ruta",
    name: "nombre",
    createToStart: "Crea un grupo para empezar",
    newGroup: "Nuevo grupo",
    addNode: "Agregar nodos",
    nodes: "Nodos",
    paths: "Rutas",
    pipes: "Pipes",
    createNewG: "Crear nuevo grupo",
    groupName: "Nombre del grupo",
    createG: "Crear grupo",
    groupSet: "Configuración del grupo",
    general: "General",
    apikey: "API Key",
    member: "Miembro",
    extra: "Extra",
    groupId: "ID de grupo",
    rfapikey: "Actualizar API Key",
    deleteGroupQ: "¿Está seguro de que desea eliminar este grupo",
    dgroup: "Eliminar grupo",
    confirmDeleteG: "Haga clic nuevamente para confirmar la eliminación",
  },
  nodes: {
    IP: "IP",
    name: "Nombre",
    mac: "Mac",
    mountP: "Punto de montaje",
    backC: "Volver al centro",
    resetL: "Restablecer diseño",
    fromIp: "Desde IP",
    from: "Desde",
    to: "Hasta",
    nameModal: {
      title: "Establecer nombre del nodo",
      label: "Nuevo nombre con",
    },
    deletePModal: {
      title: "Eliminar ruta de montaje",
      tip: "¿Estás seguro de eliminar esta ruta de montaje?",
      fromMou: "Desde punto de montaje",
      fromIp: "Desde IP",
      delSource: "Eliminar archivo fuente (¡por favor elige con cuidado!)",
    },
    cPathModal: {
      title: "Crear una nueva ruta",
      label: "Nueva ruta con",
      cPath: "Crear ruta",
    },
    delNodeModal: {
      title: "Eliminar nodo",
      tip: "¿Está seguro de que desea eliminar este nodo?",
      text: "La operación de eliminación borrará los datos relacionados con este nodo, pero si vuelve a ejecutar el programa cliente en el nodo, el nodo volverá a aparecer aquí.",
    },
    delDiskModal: {
      title: "Eliminar todas las rutas de montaje bajo este disco",
      tip: "¿Estás seguro de que quieres eliminar",
      strong: "todas las rutas de montaje en este disco",
    },
    resetLModal: {
      title: "Restablecer diseño",
      tip: "¿Está seguro de que desea restablecer el diseño?",
    },
    mfModal: {
      title: "Crear un tubo de transferencia",
      title1: "Establecer transferencia de archivos entre rutas",
      title2: "Modo de sincronización",
      label1: "Sincronización relativa",
      formText1: "Solo transfiere archivos de origen a destino",
      label2: "Sincronización absoluta",
      formText2:
        "Mantiene los archivos exactamente iguales entre el origen y el destino, los archivos adicionales en el destino serán eliminados",
      title3: "Forzar sobrescritura",
      label3: "Sobrescribir forzadamente los archivos que ya existen",
      title4: "Validar md5",
      label4: "¿Desea verificar el md5?",
      title5: "Filtros",
      title6: "Eliminar tubería",
      formText5:
        "Use patrones de comodín para filtrar los archivos que desea transferir y excluir o incluir. Si desea transferir todo, no establezca ningún filtro para 'Excluir'.",
      label5: "Excluir",
      label6: "Incluir",
      label7: "Ingresar filtros de comodín",
      alertDanger:
        "Debe establecer filtros disponibles para incluir, de lo contrario no se transferirá nada.",
      placeHolder: "Por ejemplo: *.txt",
    },
    pipeInfoModal: {
      title: "Tubería de transferencia",
      delPipe: "Eliminar tubería",
      details: "Detalles",
      speed: "Velocidad",
      log: "Registro",
      autoScroll: "Desplazamiento automático",
    },
    delPipeModal: {
      title: "Eliminar tubería",
      tip: "¿Está seguro de que desea eliminar esta tubería?",
    },
  },
  node: {
    state: "Estado",
    version: "Versión",
    newPath: "Nueva ruta",
    setNodeName: "Establecer nombre del nodo",
    deleteNode: "Eliminar nodo",
    tempatureTip: "Temperatura del disco duro de hasta {d}℃",
    deleteAll: "Eliminar todas las rutas de montaje bajo este disco",
    delPath: "Eliminar la ruta de montaje",
    total: "Total",
    free: "Libre",
    readOnly: "Solo lectura",
    readOnlyTip: "En esta ruta solo se puede leer pero no escribir",
  },
  wildcardTable: {
    td1: "Carácter",
    td2: "Descripción",
    td3: "Ejemplo",
    p1: "coincide con el carácter c (c != '\\', '-', ']')",
    p2: "coincide con cualquier secuencia de caracteres que no sean Separadores",
    finds: "encuentra",
    and: "y",
    butnot: "pero no",
    or: "o",
    p3: "coincide con cualquier carácter no Separador",
    p4: "coincide con los caracteres dentro de los corchetes.",
    p5: "coincide con el carácter c",
    p6: "coincide con el carácter c para lo <= c <= hi",
  },
  notify: {
    unableDown: "No se puede descargar temporalmente",
    netError: "Error de red, por favor inténtelo de nuevo más tarde",
    reqSuccess: "Solicitud exitosa",
    upSuccess: "Actualización exitosa",
    pleaseWait: "Solicitud enviada, por favor espere los resultados",
    invalid: "Operación inválida",
    paypalNoPro:
      "No se puede proporcionar el servicio de PayPal, por favor elija otro método",
    createdSubSuccess: "Ha creado la suscripción exitosamente",
    unableLoadPaypal:
      "No se puede cargar PayPal, por favor inténtelo de nuevo más tarde",
    groupnameExists: "El nombre del grupo ya existe",
    loginAgain: "Por favor vuelva a iniciar sesión",
    loginFirst: "Por favor inicie sesión primero",
    noline:
      "No se puede conectar a la ruta de destino. Por favor, verifica su estado.",
  },
  tickets: "Entradas",
  ticket: {
    ticket: "Tickets",
    empty: "Empty",
    subTitle:
      "Create a ticket to provide feedback on issues and seek assistance",
    open: "Open",
    close: "Close",
    all: "All",
    cancel: "Cancel",
    submit: "Submit",
    sure: "Sure",
    created_at: "Created at",
    updated_at: "Updated at",
    undone: "This action can't be undone.",
    title: "Title",
    createModal: {
      createBtn: "Create Ticket",
      placeHolder1: "Please enter title",
      placeHolder2: "Please describe the problem you have encountered",
      formText:
        "For easier communication with the staff, we recommend using English as much as possible.",
      title: "Title",
      category: "Category",
      content: "Content",
      attachment: "Attachment",
      upload: "Upload",
    },
    putModal: {
      title: "Edit Ticket",
    },
    delModal: {
      title: "Delete Ticket",
      tip: "Are you sure to delete this ticket?",
    },
    closeModal: {
      title: "Close Ticket",
      tip: "Are you sure to close this ticket?",
    },
    commented: "Commented",
    noComment: "No comment",
    yourComment: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: "Delete comment",
  },
  createdAt: "Created at",
  updatedAt: "Update at",
  close: "Close",
  open: "Open",
  all: "All",
  title: "Title",
  category: "Category",
  content: "Content",
  comment: {
    commented: "commented",
    title: "Your comment",
    placeHolder: "Please enter your comment",
    comment: "Comment",
    delComment: {
      title: "Delete comment",
      tip: "Are you sure delete this comment",
    },
  },
  balance: {
    balance: "Saldo",
    order_id: "ID de pedido",
    type: "Tipo",
    income: "Ingresos",
    outgo: "Gastos",
    created_at: "Fecha de creación",
  },
  userInfo: {
    title: "Información del usuario",
    user: "Usuario",
    username: "Nombre de usuario",
    copied: "Copiado al portapapeles",
    invitation_code: "Código de invitación",
    used_inv_code: "Código de invitación utilizado",
    code_describe:
      "Cuando otros usuarios utilicen tu código de invitación al comprar una suscripción, recibirás un descuento al adquirir una suscripción",
  },
  fileTree: {
    emptyFolder: "Carpeta vacía",
    newFile: "Nuevo archivo",
    newFilePlaceholder: "Por favor ingrese el nombre del archivo",
    newFloder: "Nueva carpeta",
    newFloderPlaceholder: "Por favor ingrese el nombre de la carpeta",
    delete: "Eliminar",
    deletePrompt: "¿Estás seguro de eliminar este archivo o carpeta?",
    errorName: "Nombre de archivo incorrecto",
    errorfloder: "Nombre de carpeta incorrecto",
    copy: "Copiar",
    paste: "Pegar",
    sameName: "Nombre de archivo duplicado",
    sameFolderName: "Nombre de carpeta duplicado",
  },
  oneTimeSync: {
    modalTitle: "Sincronización única",
    title1: "Establecer una transferencia rápida única",
  },
  subscriptions: {
    title: "Suscripciones históricas",
    planname: "Nombre del plan",
    amount: "Cantidad",
    status: "Estado",
    expired: "Caducidad",
    notExpired: "No caducado",
    createtime: "Hora de inicio",
    stopTime: "Hora de finalización",
    PayMethod: "Método de pago",
  },
  paymentMethod: {
    title: "Método de Pago",
    title1: "Método de pago predeterminado de Stripe",
    createMethodTip:
      "Actualmente no hay un método de pago predeterminado. Haga clic para crear uno",
    cardNumber: "Número de Tarjeta",
    created: "Fecha de Creación",
    expire: "Fecha de Vencimiento",
    deleteQ: "¿Está seguro de que desea eliminar este método de pago?",
    deleteTip:
      "Eliminar el método de pago predeterminado puede resultar en deducciones fallidas para futuras suscripciones, así que proceda con precaución.",
    confirmDelete:
      "Haga clic de nuevo para confirmar la eliminación del método de pago",
  },

  groupMember: {
    title: "Miembro del grupo",
    invite: "Invitar",
    remove: "Eliminar",
    removePrompt: "¿Está seguro de eliminar a este miembro?",
    ownerPrompt: "¿Estás seguro de transferir la propiedad del grupo a este miembro {name}?",
    changeOwner: "Haga clic para transferir la propiedad nuevamente",
    setOwner: "Establecer propietario",
    name: "Nombre",
    owner: "Propietario",
    invited: "Invitado",
    joined: "Tiempo de adhesión",
  },
  InviteMember: {
    title: "Invitar miembro",
    searchlable: "Buscar usuarios",
    searchTip: "Soporte para nombre de usuario, correo electrónico o ID de usuario",
    invited: "Invitado",
    member: "Miembros"
  },
  Invitation: {
    title:
      "Ha sido invitado a unirse al grupo ({groupName}) creado por {name} {email}. Haga clic en el botón para aceptar la invitación.",
    accept: "Aceptar invitación",
    describe: "Unirse a un grupo permite el acceso a",
    agreed: "de acuerdo",
    expired: "caducado",
  },
  yes: "Verdadero",
  no: "Falso",
  notification: {
    title: "Notificaciones",
  },
};

import { createStore } from "vuex";
import { isMobile, GetDownLoadCommand } from "../utils/utils";
// localStorage.setItem('_tokenInfo', JSON.stringify())

const tokenInfo = localStorage.getItem("_tokenInfo");
const userInfo = window.localStorage.getItem("_userInfo");
const theme = window.localStorage.getItem("_theme") || "dark";
const config = getConfig();
const CN = config.cn

export default createStore({
  state: {
    tokenInfo: JSON.parse(tokenInfo),
    isLogin: tokenInfo ? true : false,
    userInfo: JSON.parse(userInfo) || {},
    username: tokenInfo ? JSON.parse(tokenInfo).username : null,
    showHeader: false,
    downUrl: "",
    nodeVersion: "",
    system: "",
    nodedata: {},
    isDraging: null,
    DOWNLOAD_COMMAND: GetDownLoadCommand(),
    zindex: null,
    theme,
    ticketPurview: false,
    isMobile: isMobile(),
    production_cn: CN,
    ago: CN ? "前" : "ago",
    targetPosition: {},
    oneTimeSyncInfo: {},
    transferList: [],
    waitToPaste: {},
    paymentMethod: null,
    reSub: null,
    groupInfo: {},
    groupScale: 1,
  },
  mutations: {
    SET_LOGIN_STATUS(state, val) {
      state.isLogin = val;
    },
    SET_USERNAME(state, val) {
      state.username = val;
    },
    SET_ISADMIN(state, val) {
      state.isAdmin = val;
    },
    SET_TOKENINFO(state, val) {
      state.tokenInfo = val;
    },
    SET_USERINFO(state, val) {
      state.userInfo = val;
      localStorage.setItem("_userInfo", JSON.stringify(val));
    },
    SET_SHOWHEADER(state, val) {
      state.showHeader = val;
    },
    SET_DOWMURL(state, val) {
      state.downUrl = val;
    },
    SET_NODEVERSION(state, val) {
      state.nodeVersion = val;
    },
    SET_SYSTEM(state, val) {
      state.system = val;
    },
    SET_NODEDATA(state, val) {
      state.nodedata = val;
    },
    SET_DRAP(state, val) {
      state.isDraging = val;
    },
    SET_ZINDEX(state, val) {
      state.zindex = val;
    },
    SET_THEME(state, val) {
      state.theme = val;
      window.localStorage.setItem("_theme", val);
    },
    SET_TICKETPURVIEW(state, val) {
      state.ticketPurview = val;
    },
    SET_DRAGINGPATH(state, val) {
      state.dragingPath = val;
    },
    SET_TARGETPOSITION(state, val) {
      state.targetPosition = val;
    },
    SET_ONETIMESYNC(state, val) {
      state.oneTimeSyncInfo = val;
    },
    SET_WAITTOPASTE(state, val) {
      state.waitToPaste = val;
    },
    SET_PAYMENT_METHOD(state, val) {
      state.paymentMethod = val;
    },
    SET_RE_SUB(state, val) {
      state.reSub = val;
    },
    SET_GROUP_INFO(state, val) {
      state.groupInfo = val;
    },
    SET_GROUP_SCALE(state, val) {
      state.groupScale = val;
    },
  },
  actions: {},
  modules: {},
});

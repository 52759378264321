import { createRouter, createWebHistory } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

const Console = () => import("../views/Console.vue");
const CN = location.host.endsWith("filepipe.cn");

const beforeEnter = (to, from) => {
  const token = localStorage.getItem("_tokenInfo");

  if (!CN) {
    if (to.name == "Orders") {
      return "NotFound";
    }
  }
  // || to.name == "Balance"
  if (token) {
    return true;
  } else {
    notify({ type: "info", text: i18n.global.t("notify.loginFirst") });
    return "/";
  }
};
const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "FilePipe - Home" },
    component: () => import("@/views/overview.vue"),
  },
  {
    path: "/console",
    name: "console",
    meta: { title: "FilePipe - Console" },
    component: Console,
    beforeEnter,
  },
  {
    path: "/pricing",
    name: "pricing",
    meta: { title: "FilePipe - Pricing" },
    component: () => import("@/components/PricingTable.vue"),
    beforeEnter,
  },
  {
    path: "/invoices",
    name: "invoices",
    meta: { title: "FilePipe - Invoices" },
    component: () => import("@/views/Invoices.vue"),
    beforeEnter,
  },
  {
    path: "/terms",
    name: "terms",
    meta: { title: "FilePipe - Terms" },
    component: () => import("@/views/Terms.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: { title: "FilePipe - Privacy" },
    component: () => import("@/views/Privacy.vue"),
  },
  {
    path: "/ticket",
    name: "ticket",
    meta: { title: "FilePipe - Ticket" },
    component: () => import("@/views/ticket/Index.vue"),
    beforeEnter,
  },
  {
    path: "/ticket/:id",
    name: "ticket-detail",
    meta: { title: "FilePipe - Ticket" },
    component: () => import("@/views/ticket/Details.vue"),
    beforeEnter,
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { title: "FilePipe - NotFound" },
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/alipay/callback",
    name: "Alipay-callback",
    meta: { title: "FilePipe - callback" },
    component: () => import("@/views/AlipayCallback.vue"),
  },
  {
    path: "/user/orders",
    name: "Orders",
    meta: { title: "FilePipe - Orders" },
    component: () => import("@/views/Orders.vue"),
    beforeEnter,
  },
  {
    path: "/user/balance",
    name: "Balance",
    meta: { title: "FilePipe - Balance" },
    component: () => import("@/views/Balances.vue"),
    beforeEnter,
  },
  {
    path: "/user/subscriptions",
    name: "Subscriptions",
    meta: { title: "FilePipe - Subscriptions" },
    component: () => import("@/views/SubRecord.vue"),
    beforeEnter,
  },
  {
    path: "/notification",
    name: "Notification ",
    meta: { title: "FilePipe - Notification" },
    component: () => import("@/views/Notification.vue"),
    beforeEnter,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.name == "Console") {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  document.querySelector(".modal-backdrop")?.remove();
});

export default router;
